import { DataService } from './../../../../services/data.service';
import { ResponseModel } from './../../../../models/response-model.model';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { NotificationRequestModel } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public service: DataService) { }


  getAllNotifications(data:NotificationRequestModel): Observable<ResponseModel> {
    var url = "clo-api/notification/pushnotification";
    return this.service.get(url,true,data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  markAsReadNotifications(id: number | null = null): Observable<ResponseModel>{
    // var url = "clo-api/notification/pushnotification/mark-as-read";
    // return this.service.post(url, data, true).pipe<ResponseModel>(
    //   tap((response: any) => {
    //     return response;
    //   })
    // );
    var url = `clo-api/notification/PushNotification/mark-as-read${id?`?Id=${id}`:''}`;
    return this.service.patch(url, null, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteNotifications(id: number|null = null): Observable<ResponseModel>{
    // var url = "clo-api/notification/pushnotification/batch-delete";
    // return this.service.post(url,data,true).pipe<ResponseModel>(
    //   tap((response: any) => {
    //     return response;
    //   })
    // );
    var url = `clo-api/notification/PushNotification${id?`?Id=${id}`:''}`;
    return this.service.delete(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
