import { Injectable } from '@angular/core';
import { ResponseModel } from '../../models/response-model.model';
import { DataService } from '../../services/data.service';
import { Observable, tap } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    public service : DataService ,
    public storageservice : StorageService) {
   }

  public onDemandSytemDeclineRequestList: [] =[];
  public isQuickbooksLoggedIn = false;

   getAppointmentData(data:any):Observable<ResponseModel>{
    let url = "clo-api/cielo/appointment";
      return this.service.get(url,true,data).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }


   setQuickbooks(authCode:any):Observable<ResponseModel>{
    let url = "clo-api/identity/qboauth/tokens";
      return this.service.post(url,{authCode},true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }


   signOutQuickbooks():Observable<ResponseModel>{
    let url = "clo-api/identity/qboauth/sign-out";
      return this.service.delete(url,true,null,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

}