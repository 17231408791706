import { PaginationBaseModel } from "src/app/shared/models/pagination-base-model.model";

export class NotificationRequestModel extends PaginationBaseModel {
    isRead : boolean|null = null;
    pagination? =  new Pagination();
}

export class Pagination{
    pageNumber?: number = 1;
    pageSize?: number = 15;
  }

export class Notifications {
    id:string;
    userId:string | null;
    title:string | null;
    body:string | null;
    subtitle: string | null;
    isRead:boolean;
    isNotified:boolean;
    triggeredDateTime:string;
    timeDuration?:string;
}

export class Ids{
    ids:string[]=[];
}
