<div class="container-fluid container-height">
    <div class="row">
        <div class="col-12 shadow-right d-flex p-3 card shadow mb-0">
            <div class="d-flex justify-content-between align-items-center popUpheader">
                <div class="modalTitle d-flex">{{'COMMON.BUTTON.BUTTONLABEL.FILTER' | translate}}</div>
                <div (click)="reset()" class="d-flex align-items-center cursor-pointer">
                    <svg class="me-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 21 21">
                        <g id="reset-undo" transform="translate(-4 -2)">
                            <g id="Group_41566" data-name="Group 41566" transform="translate(4 2)">
                                <path id="Path_35107" data-name="Path 35107"
                                    d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z"
                                    transform="translate(-4 -2)" fill="#e0532f" />
                            </g>
                            <g id="Group_41567" data-name="Group 41567" transform="translate(4.231 2.95)">
                                <path id="Path_35108" data-name="Path 35108"
                                    d="M12.458,22a9.2,9.2,0,0,1-3.389-.633,10.008,10.008,0,0,1-4.66-3.694A1.036,1.036,0,0,1,4.728,16.2a1.044,1.044,0,0,1,1.483.317,7.4,7.4,0,0,0,3.6,2.85,7.782,7.782,0,0,0,4.66.211,7.383,7.383,0,0,0,3.812-2.639,8,8,0,0,0,1.588-4.327,7.34,7.34,0,0,0-1.377-4.433A7.971,7.971,0,0,0,14.788,5.43a7.053,7.053,0,0,0-4.66,0A7.971,7.971,0,0,0,6.422,8.174a1.044,1.044,0,0,1-1.483.317.954.954,0,0,1-.212-1.478,9.378,9.378,0,0,1,15.461,0,9.055,9.055,0,0,1,1.694,5.7,9.05,9.05,0,0,1-2.012,5.594,10.256,10.256,0,0,1-4.977,3.377A8.517,8.517,0,0,1,12.458,22Z"
                                    transform="translate(-4.231 -2.95)" fill="#e0532f" />
                            </g>
                        </g>
                    </svg>
                    <span class="reset-text">{{'COMMON.BUTTON.BUTTONLABEL.RESET' | translate}}</span>
                </div>
            </div>
            <div class="">
                <form class="theme-form md-height-475 my-4" ngNativeValidate #ApplyFilterForm="ngForm">
                    <div class="input-section">
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-dropdown
                                    [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                    [dropDownLabel]="'REPORTS.REPORTING.TABLE.TABLEHEADER.REQUESTTYPE'"
                                    [dropdownValue]="filterModel.appointmentCategoryId"
                                    [dropDownList]="loadDropdownDataService.commonGetDropdownModel.requestCategories"
                                    (dropdownValueEmit)="filterModel.appointmentCategoryId= $event" [isRequired]="false"
                                    [id]="'user_Requestor'">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-dropdown
                                    [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                    [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.ORGANIZATION'"
                                    [dropdownValue]="filterModel.organizationId"
                                    [dropDownList]="loadDropdownDataService.commonGetDropdownModel.organizations"
                                    (dropdownValueEmit)="filterModel.organizationId = $event" [isRequired]="false"
                                    [id]="'user_organization'">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-dropdown
                                    [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                    [dropDownLabel]="'REPORTS.REPORTING.TABLE.TABLEHEADER.INTERPRETER'"
                                    [dropdownValue]="filterModel.interpreterId"
                                    [dropDownList]="loadDropdownDataService.commonGetDropdownModel.appointmentInterpreters"
                                    (dropdownValueEmit)="filterModel.interpreterId=$event" [isRequired]="false"
                                    [id]="'Interpreter'">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-dropdown
                                    [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                    [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST'"
                                    [dropdownValue]="filterModel.interpreterGenderRequestId"
                                    [dropDownList]="loadDropdownDataService.commonGetDropdownModel.genders"
                                    (dropdownValueEmit)="filterModel.interpreterGenderRequestId=$event" [isRequired]="false"
                                    [id]="'user_GenderRequest'">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12 filter-sidebar">
                                <label [for]="'user_Language'" class="form-label f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LANGUAGE' | translate}}</label>
                                    <app-custom-dropdown
                                        #languageDropdown
                                        [id]="'user_Language'" [isRequired]="false"
                                        (buttonOutputEvent)="addNewLanguages($event);"
                                        [singleSelectedlanguage]="selectedlanguage"
                                        [languageDropDownData]="languagesListData" [addLanguageIcon]="false"
                                        [dynamic_class]="'user_Language'">
                                    </app-custom-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-range-datepicker [isRequired]="false"
                                 [datePickerLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.DATERANGE'"
                                 [datepickername]="'customDatePicker'"
                                 [isResetIconVisible]="true"
                                 [fromDateFromParent]="filterModel.dateRange.startDate"
                                 [toDateFromParent]="filterModel.dateRange.endDate" [isMinDate]="false"
                                 (selectedDates)="getSelectedDates($event)">
                                </app-range-datepicker>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-dropdown
                                    [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                    [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.FILTER.STATE'"
                                    [dropdownValue]="filterModel.locationId"
                                    [dropDownList]="loadDropdownDataService.commonGetDropdownModel.states"
                                    (dropdownValueEmit)="filterModel.locationId = $event" [isRequired]="false"
                                    [id]="'user_Location'">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-time-picker
                                [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STARTTIME' | translate"
                                [id]="'startTime'" [name]="'startTime'" [isRequired]="false"
                                [resetIconVisible]="true"
                                [timePickerValue]="filterModel?.startTimeRange?.startTime"
                                (timePickerValueEmit)="filterModel.startTimeRange.startTime=$event;">
                                </app-time-picker>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <app-time-picker
                                [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDTIME' | translate"
                                [id]="'endTime'" [name]="'endTime'" [isRequired]="false"
                                [resetIconVisible]="true"
                                [timePickerValue]="filterModel?.startTimeRange?.endTime"
                                (timePickerValueEmit)="filterModel.startTimeRange.endTime=$event;">
                                </app-time-picker>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 mt-3 justify-content-center ">
                        <app-button 
                            [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'" 
                            (click)="activeModal.close()" 
                            [buttonClass]="'button-secondary px-3'" 
                            [buttonType]="'submit'" 
                            [id]="'user_cancel_button'" 
                            [name]="'user_cancel_button'">
                        </app-button>
                       
                        <app-button 
                            [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.APPLY'"
                            [buttonClass]="'button-primary px-3'" 
                            (click)="applyFilter()">
                        </app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>