import { FormSettingsService } from 'src/app/components/AdminSettings/form-settings/form-settings.service';
import { EntryState, FormSettingTitle, GetLookupFormEnum } from '../../enum/common-enum';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ResponseModel } from '../../models/response-model.model';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent extends CommonService implements OnChanges {
  @Input() languageDropDownData: any;
  @Input() selectedlanguagesData: any;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input() addLanguageIcon = true;
  @Input() singleSelectedlanguage?: any;
  @Input() isDisabled: boolean = false;
  @Input() dynamic_class: string;
  @Input() placeHolderText: string;
  @Input() isRequired: boolean;
  @Input() id?: string;
  @ViewChild('dropdown') dropdown: NgbDropdown;


  entryState = EntryState;
  dropdownOpen = false;
  selectedOption: any;
  variantselectedOption: any;
  languageDropDownDataArray: any[] = [];
  getLookupFormEnum = GetLookupFormEnum;
  formSettingTitle = FormSettingTitle;
  languagesListData: any;
  selectedLanguage: any;

  constructor(public formSettingsService: FormSettingsService,public storageService:StorageService) {
    super();
    this.selectedLanguage = this.storageService.retrieve("selectedLanguage")
    this.getLanguages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.languagesListData != undefined) {
      this.languageDropDownDataArray = this.languagesListData;
    }
    if (this.singleSelectedlanguage?.languageVariant != undefined) {
      this.variantselectedOption = this.singleSelectedlanguage?.languageVariant;
    }
    this.selectedOption = this.singleSelectedlanguage?.language;
  }


  getLanguages() {
    this.formSettingsService
      .getAllFormSettings(
        this.getLookupFormEnum.LanguageAndVariant, undefined, undefined
      )
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded === true) {
          this.languagesListData = res.response.lookupTables.filter(
            (item) =>
              item.value ==
              this.formSettingTitle.languagesAndVariants
          )[0].lookupValues;

          this.languagesListData =  this.languagesListData.filter(x=>x.isActive==true)
          this.languageDropDownDataArray =  this.languagesListData;
        }
        else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      });
  }


  getDropdownHeight() {
    return this.languageDropDownDataArray && this.languageDropDownDataArray.length < 6 ? 'auto' : '10rem';
  }
  hasDisabled(language, languageVariant): boolean {
    //#region for disabled single selected at time
    // if(!this.addLanguageIcon && this.selectedlanguagesData != undefined){
    //   if(languageVariant == undefined)
    //   {
    //     return this.selectedlanguagesData?.find((a) => (a?.id == language.languageVariant[0]?.id) &&  a.entryState != this.entryState.DELETE);
    //   }
    //   else{
    //     return this.selectedlanguagesData?.find((a) => (a?.id == languageVariant.id) &&  a.entryState != this.entryState.DELETE);
    //   }
    // }
    // else{
    //   if(languageVariant == undefined)
    //   {
    //     return this.selectedlanguagesData?.find((a) => (a?.id == language.languageVariant[0]?.id) &&  a.entryState != this.entryState.DELETE);
    //   }
    //   else{
    //     return this.selectedlanguagesData?.find((a) => (a?.id == languageVariant.id) &&  a.entryState != this.entryState.DELETE);
    //   }
    // }
    //#endregion
    if (languageVariant == undefined) {
      return this.selectedlanguagesData?.find((a) => (a?.id == language?.languageVariant[0]?.id) && a.entryState != this.entryState.DELETE);
    }
    else {
      return this.selectedlanguagesData?.find((a) => (a?.id == languageVariant.id) && a.entryState != this.entryState.DELETE);
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(language, languageVariant) {
    if (languageVariant != undefined) {
      this.variantselectedOption = languageVariant;
    }
    this.selectedOption = language;
    if (!this.addLanguageIcon && this.selectedOption) {
      this.buttonOutputEvent.emit({ language: language, languageVariant: languageVariant });
    }
    this.dropdownOpen = false;
    this.closeSuboptions();
  }

  closeSuboptions() {
    this.languageDropDownDataArray?.map(x => x.isOpen = false);
  }

  addLangugage(language, languageVariant) {
    if (this.selectedOption) {
      this.buttonOutputEvent.emit({ language: language, languageVariant: languageVariant })
      this.selectedOption = undefined;
      this.variantselectedOption = undefined;
    }
  }

  onOutsideClick(event) {
    this.dropdownOpen = false;
    this.closeSuboptions();
  }

  clearSelection(event) {
    event?.stopPropagation();
    this.selectedOption = undefined;
    this.buttonOutputEvent.emit({ language: undefined, languageVariant: undefined });
  }

  @ViewChild('content') contentElement: ElementRef;
  isTextOverflowing: boolean = false;

  checkTextOverflow(text: string): void {
    const element = document.createElement('span');
    element.textContent = text;
    element.style.visibility = 'hidden';
    element.style.position = 'absolute';
    element.style.whiteSpace = 'nowrap';
    // Get the font family of the container element
    const fontFamily = window.getComputedStyle(this.contentElement.nativeElement).fontFamily;
    const fontSize = window.getComputedStyle(this.contentElement.nativeElement).fontSize;
    const letterSpacing = window.getComputedStyle(this.contentElement.nativeElement).letterSpacing;
    element.style.fontFamily = fontFamily;
    element.style.fontSize = fontSize;
    element.style.letterSpacing = letterSpacing;

    document.body.appendChild(element);
    this.isTextOverflowing = element.offsetWidth + 0.5 > this.contentElement?.nativeElement?.offsetWidth;
    document.body.removeChild(element);
  }

}