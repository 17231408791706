import { Injectable } from '@angular/core';
import { baseUrl } from '../../../environments/environment';
import { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { StorageService } from './storage.service';
import { AppointmentChangeScope, HubConstant } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})

export class RealtimeService {

  private hubConnectionForCieloHub?: HubConnection;

  constructor(private storageService: StorageService) { }
  private liveDataUpdates = new Subject<any>();
  public liveDataUpdates$ = this.liveDataUpdates.asObservable();

  private ondemandRequestDecliend = new Subject<any>();
  public ondemandRequestDecliend$ = this.ondemandRequestDecliend.asObservable();

  private deactivateUser = new Subject<any>();
  public deactivateUser$ = this.deactivateUser.asObservable();

  private permissionChanged = new Subject<any>();
  public permissionChanged$ = this.permissionChanged.asObservable();

  // private groupJoinStatus: Map<string, boolean> = new Map();
  public cieloapi_connectionId :string = "";

  // # region HubConnectionForCielo
  async createHubConnectionForCielo() {
    this.hubConnectionForCieloHub = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrl}clo-hub`, {
        // skipNegotiation: true,
        // headers: { 'Authorization': `Bearer ${accessToken}` },
        accessTokenFactory: () => {
          return this.storageService.retrieve("authorizationDataIdToken") || '';  // Return the access token as a string
        },
        // transport: signalR.HttpTransportType.WebSockets
      }).withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information) // Add logging for better debugging
      .build();

    this.setupCieloHubConnectionHandlers();
    await this.startConnectionForCieloHub();

    this.hubConnectionForCieloHub?.on(HubConstant.StatisticsUpdatedFunction, (data) => {
      console.log("Dashboard update received: ", data);   // Handle the Dashboard update data
      this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.AppointmentCreatedFunction, (res) => { 
      // Handle the appointment created data
      const data  = { 
        appointmentChangeScope : AppointmentChangeScope.AppointmentCreated,
        response : res
      };
      if(res != null)this.liveDataUpdates.next(data);
    });
    
    this.hubConnectionForCieloHub?.on(HubConstant.AppointmentUpdatedFunction, (res) => {
      // Handle the appointment update data
      console.log("Appointment update received: ", res);  
      const data  = { 
        appointmentChangeScope : AppointmentChangeScope.AppointmentUpdated,
        response : res
      };  
      if(res != null)this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.AppointmentStatusUpdatedFunction, (res) => {
      // Handle the appointment status update data
      console.log("Appointment  status update received: ", res);  
      const data  = { 
        appointmentChangeScope : AppointmentChangeScope.AppointmentStatusUpdated,
        response : res
      };  
      if(res != null) this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.AppointmentAssignEnabledFunction, (res) => {
      // Handle the appointment assign enabled 
      console.log("Appointment assign button received: ", res);  
      const data  = { 
        appointmentChangeScope : AppointmentChangeScope.AutoAssignedPendingRequestReAssignEnabled,
        response : res
      };
      if(res != null) this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.AuditLogCreatedFunction, (data) => {
       // Handle the AuditLog update data
      console.log("AuditLog update received: ", data); 
      this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.AppointmentOnDemandAutoDeclinedFunction, (data) => {
      // Handle the Ondemand system decliend
      console.log("Ondemand system decliend request received: ", data); 
      this.ondemandRequestDecliend.next(data);
    });

    // for identity
    this.hubConnectionForCieloHub?.on(HubConstant.LogoutUserFunction, (data, ) => {
      console.log("Logout update received: ", data);
      this.deactivateUser.next(true);
    });

    this.hubConnectionForCieloHub?.on(HubConstant.PermissionUpdateFunction, (data) => {
      console.log("Permissions update received: ", data);
      this.permissionChanged.next(data);
    });
  }

  private setupCieloHubConnectionHandlers() {
    this.hubConnectionForCieloHub?.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`, error);
    });

    this.hubConnectionForCieloHub?.onreconnected((connectionId) => {
      console.log(`Reconnected. Connection ID: ${connectionId}`);
    });

    this.hubConnectionForCieloHub?.onclose((error) => {
      console.error('Connection closed:', error);
      // setTimeout(() => this.startConnection(), 5000); // Attempt to reconnect after 5 seconds
    });
  }

  private async startConnectionForCieloHub() {
    if (this.hubConnectionForCieloHub.state === signalR.HubConnectionState.Disconnected) {
      await this.hubConnectionForCieloHub?.start()
        .then(() => {
          console.log('Connection started');
          this.cieloapi_connectionId = this.hubConnectionForCieloHub?.connectionId;
        })
        .catch(err => {
          console.error('Error while starting connection: ' + err);
          // setTimeout(() => this.startConnection(), 5000); // Retry after a delay if failed
        });
    } else if (this.hubConnectionForCieloHub.state === signalR.HubConnectionState.Connected) {
      console.log('Already connected, no need to start again');
    } else {
      console.log('Connection is already in progress or connected');
    }
  }

  public disposeCieloHubConnection() {
    if (this.hubConnectionForCieloHub && this.hubConnectionForCieloHub?.state !== signalR.HubConnectionState.Disconnected) {
      this.hubConnectionForCieloHub?.stop()?.then((res) => console.log("The connection has been stopped", res))?.catch(err => console.error('Error while stopping connection:', err)).finally(()=>{
        // this.resetConnectionId();
      });
    }
  }

}