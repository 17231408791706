import { OnlyNumbersDirective } from './directive/only-numbers.directive';
import { OnlyAlphabetsDirective } from './directive/only-alphabets.directive';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { InputComponent } from './common-components/input/input.component';
import { ButtonComponent } from './common-components/button/button.component';
import { SearchInputComponent } from './common-components/search-input/search-input.component';
import { CheckboxComponent } from './common-components/checkbox/checkbox.component';
import { DropdownComponent } from './common-components/dropdown/dropdown.component';
import { SvgComponent } from './common-components/svg/svg.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { SwitchComponent } from './common-components/switch/switch.component';
import { TextAreaComponent } from './common-components/text-area/text-area.component';
import { CustomDropdownComponent } from './common-components/custom-dropdown/custom-dropdown.component';
import { RangeDatepickerComponent } from './common-components/range-datepicker/range-datepicker.component';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { MinTimeValidatorDirective } from './directive/min-time-validator.directive';
import { TimeFormatPipe } from './custom-pipes/time-format.pipe';
import { TiemFormatChangePipe } from './custom-pipes/tiem-format-change.pipe';
import { FilterPipe } from './custom-pipes/filter.pipe';
import { RelativeTimePipe } from './custom-pipes/relative-time.pipe';
import { TruncatePipe } from './custom-pipes/truncate.pipe';
import { DateFormatToDayPipe } from './custom-pipes/dateFormatToDay.pipe';

import { NgApexchartsModule } from 'ng-apexcharts';


import { RadiobuttonComponent } from './common-components/radiobutton/radiobutton.component';
import { StopPropagationDirective } from './directive/stop-propagation.directive';
import { MultiselectDropdownComponent } from './common-components/multiselect-dropdown/multiselect-dropdown.component';
import { NewRangeDatePickerComponent } from './common-components/new-range-date-picker/new-range-date-picker.component';
import { TimeFormatAMPMPipe } from './custom-pipes/time-format-ampm.pipe';
import { DateSingleComponent } from './common-components/date-single/date-single.component';
import { TimePickerComponent } from './common-components/time-picker/time-picker.component';
import { TimeFormatCheckDirective } from './directive/time-format-check.directive';
import { LoadDropdownDataComponent } from './common-components/load-dropdown-data/load-dropdown-data.component';
import { SvgLogoComponent } from './common-components/svg-logo/svg-logo.component';
import { FormatTimeInputPipe } from './custom-pipes/format-time-input.pipe';
import { CustomSmallDropdownComponent } from './common-components/custom-small-dropdown/custom-small-dropdown.component';
import { PageSummaryComponent } from './common-components/page-summary/page-summary.component';
import { TimeRangePickerComponent } from './common-components/time-range-picker/time-range-picker.component';
import { RequestDeclinedPopUpComponent } from './common-components/popup/request-declined-pop-up/request-declined-pop-up.component';
import { TimeMMSSFormatPipe } from './custom-pipes/time-mmssformat.pipe';
import { ClickOutsideDirective } from './directive/click-outside.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    InputComponent,
    ButtonComponent,
    SearchInputComponent,
    CheckboxComponent,
    DropdownComponent,
    SvgComponent,
    CommonPopupComponent,
    SwitchComponent,
    TextAreaComponent,
    CustomDropdownComponent,
    RangeDatepickerComponent,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NotificationSidebarComponent,
    MinTimeValidatorDirective,
    TimeFormatPipe,
    TiemFormatChangePipe,
    DateFormatToDayPipe,
    FilterPipe,
    RelativeTimePipe,
    TruncatePipe,
    RadiobuttonComponent,
    StopPropagationDirective,
    MultiselectDropdownComponent,
    NewRangeDatePickerComponent,
    TimeFormatAMPMPipe,
    DateSingleComponent,
    TimePickerComponent,
    TimeFormatCheckDirective,
    LoadDropdownDataComponent,
    SvgLogoComponent,
    FormatTimeInputPipe,
    CustomSmallDropdownComponent,
    PageSummaryComponent,
    TimeRangePickerComponent,
    RequestDeclinedPopUpComponent,
    TimeMMSSFormatPipe,
    ClickOutsideDirective,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, DragulaModule.forRoot(), TranslateModule, CarouselModule, SwiperModule,NgxDatatableModule,NgApexchartsModule,NgbDatepickerModule],
  providers: [NavService, LayoutService, DecimalPipe],
  exports: [NgbModule, FormsModule, ReactiveFormsModule, TranslateModule,NgxDatatableModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, SvgIconComponent, SwiperModule,InputComponent,

    ButtonComponent,SearchInputComponent,CheckboxComponent,DropdownComponent,MultiselectDropdownComponent,SvgComponent,SwitchComponent , TextAreaComponent,CustomDropdownComponent,RangeDatepickerComponent,RadiobuttonComponent,OnlyAlphabetsDirective,
    OnlyNumbersDirective,StopPropagationDirective,MinTimeValidatorDirective,TimeFormatPipe,TimeFormatAMPMPipe,TiemFormatChangePipe,TruncatePipe,DateFormatToDayPipe,NgApexchartsModule,NewRangeDatePickerComponent,NgbDatepickerModule,DateSingleComponent,TimePickerComponent,
    LoadDropdownDataComponent,SvgLogoComponent,CustomSmallDropdownComponent,PageSummaryComponent,TimeRangePickerComponent,TimeMMSSFormatPipe,ClickOutsideDirective],

   

})
export class SharedModule {}
