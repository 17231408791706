import { LoginService } from './../../../../../auth/login/login.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SelectOrganizationModel } from 'src/app/auth/select-organization/select-organization.model';
import { SelectOrganizationService } from 'src/app/auth/select-organization/select-organization.service';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/components/AdminSettings/user-management/user-management.service';
import { Subscription } from 'rxjs';
import { RealtimeService } from 'src/app/shared/services/realtime.service';
import { MULTIPLEORG_ACCESSTOKEN, Roles, USER_ORGANIZATIONS } from 'src/app/shared/enum/common-enum';

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit , OnDestroy {
  public userName: string;
  public role:string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  userDetails:any;
  selectedOrganization:number;
  selectOrganizationModel = new SelectOrganizationModel();
  updateMyInfoSubscription :Subscription
  private deactivateUserDataUpdateSubscription: Subscription;
  userOrganizations = [];
  roles = Roles;
  constructor(
    public userManagementService:UserManagementService,
    public router: Router,public loginService:LoginService,public realtimeService: RealtimeService,public storageservice: StorageService,public selectOrganizationService:SelectOrganizationService,public toaster:ToastrService) {
  }


  ngOnInit() {
    this.updateMyInfoSubscription = this.userManagementService.updateMyAccountInfo.subscribe((res)=>{
      if(true){
        this.getUserDetails();
      }
    });

    this.getUserDetails();
    this.subscribeToRealTimeUpdates();
  }

  subscribeToRealTimeUpdates(): void {
    this.deactivateUserDataUpdateSubscription = this.realtimeService?.deactivateUser$.subscribe(liveData => {
      if(liveData)
        this.logoutFunc();
    });
  }

  getUserDetails(){
    this.userOrganizations = this.storageservice.retrieve(USER_ORGANIZATIONS)??[];
    this.userDetails = this.storageservice.retrieve('userAccountDetails');
    if(this.userDetails != undefined){
      this.userName = this.userDetails?.firstName+ ' ' + this.userDetails?.lastName;
      this.role = this.userDetails?.organizations[0]?.role?.value;
      this.selectOrganizationModel.organizationId = this.userDetails?.organizations[0]?.organization?.id;
    };
  }

  
  logoutFunc() {
    const refreshToken = this.storageservice.retrieve("refreshToken");
    this.loginService.SignOut({refreshToken}).subscribe((res:ResponseModel)=>{
      if(res.isSucceeded){
        this.router.navigateByUrl('auth/login');
      }
      else{
        if (res.message != undefined) {
          this.toaster.error(res.message);
      }
      if (res?.errors?.length > 0) {
          this.toaster.error(res.errors[0]);
      }
      }
    });
  }

  navigate(){
    // this.router.navigate([`user-settings/userdetails/${this.userDetails.id}`]);
    this.router.navigate([`user-settings/userdetails/loginuser`]);

  }

  selectOrganization(organization){
    const accessTokenForMulipleOrg = this.storageservice.retrieve(MULTIPLEORG_ACCESSTOKEN);
    const currentAccessToken = this.storageservice.retrieve("authorizationDataIdToken");
    this.storageservice.store("authorizationDataIdToken", accessTokenForMulipleOrg);

    this.selectOrganizationModel.organizationId = organization.id;
    // this.selectOrganizationModel.refreshToken = this.storageservice.retrieve('refreshToken');
    this.selectOrganizationService.newSelectedOrganization(this.selectOrganizationModel)
        .subscribe({
          next:(res: ResponseModel) => {
            if(res?.isSucceeded){
              const {accessToken , refreshToken} = res?.response;
              this.storageservice.store("authorizationDataIdToken",accessToken);
              this.storageservice.store("refreshToken",refreshToken);
              this.userDetails.organizations = this.userOrganizations?.filter((x)=> x?.organization?.id == this.selectOrganizationModel?.organizationId );
              this.storageservice.store("userAccountDetails",this.userDetails);
              this.reloadComponent();
            } else{
                if (res.message != undefined) this.toaster.error(res.message);
                if (res?.errors?.length > 0) this.toaster.error(res.errors[0]);
            }
        } ,error:((err)=>{
          console.log(err);
        })
      })
  }

  reloadComponent() {
      const userDetails =  this.storageservice.retrieve('userAccountDetails');
      if ((userDetails?.organizations[0]?.role?.value == this.roles?.Requester) && (this.router?.url?.includes('admin-setting'))) {
        this.router.navigate(["/dashboard"]); 
        setTimeout(()=>{
          location.reload();
        },200)
      } else{
        location.reload();
      }
  }


  ngOnDestroy(): void {
    this.updateMyInfoSubscription?.unsubscribe()
    this.deactivateUserDataUpdateSubscription?.unsubscribe();
  }
  
}