import { Injectable } from '@angular/core';
import { ResponseModel } from '../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';
import { ChangePasswordModel, ForgetPasswordModel, LoginModel, ResetPasswordModel, Verification, VerifyOtpModel } from './login.model';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public service:DataService) { }

  // sign in function
  SignIn(data: LoginModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/sign-in";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // sign-out in function
  SignOut(data): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/sign-out";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // refresh Token api call
  refreshToken(data: any): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/refresh-token";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // send opt to mail
  sendOtpOnEmail(data: ForgetPasswordModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/forgot-password";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // verify OTP 
  verifyOTP(data: VerifyOtpModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/verify-otp";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // Reset Password
  resetPassword(data: ResetPasswordModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/reset-password";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // Reset Password
  changePassword(data: ChangePasswordModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/change-password";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    getAccessAccordingToPermission(): Observable<ResponseModel> {
      let url = "clo-api/identity/permissions/me";                                          
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    resetPasswordWithLink(data:any): Observable<ResponseModel> {
      let url = "clo-api/identity/auth/reset-password-with-link";                                          
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    //2fa verification
    twoFactorVerification(data:Verification): Observable<ResponseModel> {
      let url = "clo-api/identity/auth/2fa/verify-otp";                                          
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    //2fa verification
    resendOtpTwoFactorVerification(email:string): Observable<ResponseModel> {
      let url = "clo-api/identity/auth/2fa/resend-otp"; 
      const data = {email}                                         
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
}