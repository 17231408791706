import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TimeRangePickerComponent {
  timePickerVisible: boolean;
  @Input() timeRangePickerValue1: string;
  @Input() timeRangePickerValue2: string;
  @Input() id: string;
  @Input() name: string;
  @Input() inputLabel: string;
  @Input() isRequired: boolean;
  @Input() minTime: NgbTimeStruct;
  @Input() inputDisabled: boolean;
  @Input() resetIconVisible : boolean = false;
  ngbTimePickerValue1: NgbTimeStruct;
  ngbTimePickerValue2: NgbTimeStruct;
  @Output() timeRangePickerValue1Emit: EventEmitter<string> = new EventEmitter();
  @Output() timeRangePickerValue2Emit: EventEmitter<string> = new EventEmitter();
  timeRangePickerInput = TimeRangePickerInput

  constructor(private elementRef: ElementRef) {
      this.disableAutocomplete();
      this.setCurrentTimeForFirstPicker();
      this.setCurrentTimeForSecondPicker();
  }

  disableAutocomplete() {
  const formFields = document.getElementsByTagName('input');
  for (let i = 0; i < formFields.length; i++) {
    formFields[i].setAttribute('autocomplete', 'off');
  }
}

  setCurrentTimeForFirstPicker() {
      const currentTime = new Date();
      this.ngbTimePickerValue1 = {
          hour: currentTime.getHours(),
          minute: currentTime.getMinutes(),
          second: 0,
      };
      return this.ngbTimePickerValue1;
  }

  setCurrentTimeForSecondPicker() {
    const currentTime = new Date();
    this.ngbTimePickerValue2 = {
        hour: currentTime.getHours(),
        minute: currentTime.getMinutes(),
        second: 0,
    };
    return this.ngbTimePickerValue2;
}

  ngOnInit() {

      const hoursInput = this.elementRef.nativeElement.querySelectorAll('[aria-label="Hours"]');
      const minutesInput = this.elementRef.nativeElement.querySelectorAll('[aria-label="Minutes"]');
  
      if (hoursInput && minutesInput) {
      minutesInput?.forEach((element , index)=>{
        element?.addEventListener('keydown', this.keydown.bind(this));
       element?.setAttribute('data-input-customid', index);
      })
      hoursInput?.forEach((element,index)=>{
        element?.addEventListener('keydown', this.keydown.bind(this));
        element?.setAttribute('data-input-customid', index);
      })
      }
  }
  clearSelection(event){
    this.timeRangePickerValue1 = undefined;
    this.timeRangePickerValue1Emit.emit(this.timeRangePickerValue1);
    this.timeRangePickerValue2 = undefined;
    this.timeRangePickerValue2Emit.emit(this.timeRangePickerValue2);
  }
  
  updateTimeForFirstPicker(selectedTime: NgbTimeStruct) {
      if(!selectedTime) return;
      this.ngbTimePickerValue1 = selectedTime;
      const formattedTime = this.formatTime(this.ngbTimePickerValue1);
      // Update the input field value
      this.timeRangePickerValue1 = formattedTime;
      this.timeRangePickerValue1Emit.emit(this.timeRangePickerValue1);
  }

  updateTimeForSecondPicker(selectedTime: NgbTimeStruct) {
    if(!selectedTime) return;
    this.ngbTimePickerValue2 = selectedTime;
    const formattedTime = this.formatTime(this.ngbTimePickerValue2);
    // Update the input field value
    this.timeRangePickerValue2 = formattedTime;
    this.timeRangePickerValue2Emit.emit(this.timeRangePickerValue2);
}

  formatTime(time: NgbTimeStruct): string {
      // Format the time as HH:MM
      const hour = time.hour.toString().padStart(2, "0");
      const minute = time.minute.toString().padStart(2, "0");
      return `${hour}:${minute}`;
  }

  // Method to compare two NgbTimeStruct objects
  isTimeEarlier(time1: NgbTimeStruct, time2: NgbTimeStruct): boolean {
      if (time1?.hour < time2.hour) {
          return true;
      } else if (time1?.hour === time2.hour && time1?.minute < time2.minute) {
          return true;
      } else {
          return false;
      }
  }

  ngOnChanges() {
      if (this.timeRangePickerValue1) {
          this.timeRangePickerValue1Emit.emit(this.timeRangePickerValue1);
          this.timeRangePickerValue1 = this.removeSecondsFromTimeString(
              this.timeRangePickerValue1
          );
      }
      if (this.timeRangePickerValue2) {
        this.timeRangePickerValue2Emit.emit(this.timeRangePickerValue2);
        this.timeRangePickerValue2 = this.removeSecondsFromTimeString(
            this.timeRangePickerValue2
        );
    }
  }

  removeSecondsFromTimeString(timeString: string): string {
      // Split the time string by ":"
      if (timeString?.length > 5) {
          const timeParts = timeString.split(":");

          // Remove the last element (seconds)
          timeParts.pop();

          // Join the remaining parts back into a string
          const formattedTime = timeParts.join(":");

          return formattedTime;
      } else {
          return timeString;
      }
  }

  changeCurrentTimeToPreviousValue(timeRangePickerInput) {
    if(timeRangePickerInput == TimeRangePickerInput.FirstTimpePicker){
        if (this.timeRangePickerValue1 && this.timeRangePickerValue1 != "") {
          this.ngbTimePickerValue1 = this.parseTimeStringToNgbTimeStruct(
              this.timeRangePickerValue1
          );
      } else {
          this.ngbTimePickerValue1 = this.setCurrentTimeForFirstPicker();
      }
    } else if(timeRangePickerInput == TimeRangePickerInput.SecondTimpePicker){
        if (this.timeRangePickerValue2 && this.timeRangePickerValue2 != "") {
          this.ngbTimePickerValue2 = this.parseTimeStringToNgbTimeStruct(
              this.timeRangePickerValue2
          );
      } else {
          this.ngbTimePickerValue2 = this.setCurrentTimeForSecondPicker();
      }
    }
  }

  parseTimeStringToNgbTimeStruct(timeString: string): NgbTimeStruct {
      const [hours, minutes] = timeString.split(":").map(Number);
      const time: NgbTimeStruct = { hour: hours, minute: minutes, second: 0 };
      return time;
  }

  handleKeyDown(event: KeyboardEvent) {
      if (event.key != "Tab" && event.key != "Enter") {
          event.preventDefault();
      }
  }


 onHoursInputKeyPress(event: KeyboardEvent): void {
  this.restrictInput(event, 12);
}

 onMinutesInputKeyPress(event: KeyboardEvent): void {
  this.restrictInput(event, 59);
}

 restrictInput(event: KeyboardEvent, maxValue: number): void {
  const input = event.key;
  const currentValue = (event.target as HTMLInputElement).value + input;
  const newValue = parseInt(currentValue);

  if (isNaN(newValue) || newValue > maxValue) {
    event.preventDefault();
  }
}

keydown(event){
  const currentElement = (event.target as HTMLInputElement);
  if(event.code =='Enter'){
      event?.preventDefault()
      this.updateTimeForFirstPicker(this.ngbTimePickerValue1);
      this.updateTimeForSecondPicker(this.ngbTimePickerValue2);
      if(currentElement?.ariaLabel == 'Hours'){
          const minutesInputs = this.elementRef?.nativeElement?.querySelectorAll('[aria-label="Minutes"]');
          minutesInputs[currentElement?.dataset?.inputCustomid]?.select(); 
          // minutesInput?.focus();
          } else if (currentElement?.ariaLabel == 'Minutes'){
              const element = this.elementRef?.nativeElement?.querySelectorAll('.ngb-tp-meridian');
          }
      }
  }


ngOnDestroy(): void {
  const hoursInput = this.elementRef?.nativeElement?.querySelector('[aria-label="hours"]');
  const minutesInput = this.elementRef?.nativeElement?.querySelector('[aria-label="minutes"]');

  if (hoursInput && minutesInput) {
    hoursInput.removeEventListener('keypress', this.onHoursInputKeyPress.bind(this));
    minutesInput.removeEventListener('keypress', this.onMinutesInputKeyPress.bind(this));
  }
}

  openChange(event){
      if(event){
          setTimeout(()=>{
              const hoursInput = this.elementRef.nativeElement.querySelector('[aria-label="Hours"]');
              hoursInput?.select();
          },100)
      } else {
        if(this.timeRangePickerValue2 &&  !this.timeRangePickerValue1){
          this.updateTimeForFirstPicker(this.ngbTimePickerValue2);
        } else if (this.timeRangePickerValue1 &&  !this.timeRangePickerValue2){
          this.updateTimeForSecondPicker(this.ngbTimePickerValue1);
        }
      }
  }
}

export enum TimeRangePickerInput {
  FirstTimpePicker = 1,
  SecondTimpePicker = 2
}