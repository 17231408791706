import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent {
  @Input() public src:any="";
  @Input() public classSvg:any;
  
  constructor(private el: ElementRef,
    private http: HttpClient,) { }

  ngOnInit(): void {
    if(this.src != undefined){
      this.http.get(this.src,{responseType: 'text'}).subscribe(svg => {
        if(this.src != ''){
          this.el.nativeElement.innerHTML = svg;
        }
    });
    }
  }
}