import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { ResponseModel } from "../../../shared/models/response-model.model";
import { DataService } from "../../../shared/services/data.service";
import { InsertUpdateFormSettings } from './form-settings.model';

@Injectable({
  providedIn: 'root'
})
export class FormSettingsService {

  constructor(public service: DataService) { }

  public reassignDropdownData: Subject<any> = new Subject<any>();

  getAllFormSettings(id_1,id_2,id_3 = undefined): Observable<ResponseModel> {
    var url = "clo-api/cielo/Lookup?";
    if (id_1) {
      url += "LookupTablesEnumIds="+id_1;
    }
    if (id_2) {
      if(id_1 == undefined){
        url += 'LookupTablesEnumIds=' + id_2;
      }
      else{
        url += '&LookupTablesEnumIds=' + id_2;
      }
    }
    if (id_3 !== undefined) {
      if (id_1 === undefined && id_2 === undefined) {
        url += "LookupTablesEnumIds=" + id_3;
      } else {
        url += "&LookupTablesEnumIds=" + id_3;
      }
    }
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  InsertUpdateFormSettings(data: InsertUpdateFormSettings): Observable<ResponseModel> {
    let url = "clo-api/cielo/lookup";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


}