import { PaginationBaseModel } from "../../../../src/app/shared/models/pagination-base-model.model";
import { TimeModel } from "../appointments-and-scheduling/request-list/request-list.model";
import { DateModel } from "../dashboard/dashboard.model";

export class GetReportsModel extends PaginationBaseModel{
    id?: number;
    appointmentCategoryId?: number;
    appointmentInternalStatusId?: number;
    organizationId?: number;
    interpreterId?: number; // add new field
    interpreterGenderRequestId?: number;
    languageId?: number;
    locationId?: number;
    calendarTypeId?: number;
    date?: string;
    communicationTypeId? :any;
    groupId? : any;
    serviceId?:any;
    dateRange? = new DateModel();
    startTimeRange?= new TimeModel();
    endTimeRange? = new TimeModel();
    isGenderNotPreferred? : boolean;
    appointmentStatusIds?:number[]= [];
}

export class AppointmentReportsResponse {
    appointmentNumber: string;
    organization: string;
    userGroup: string;
    requester: string;
    requestType: string;
    language: string;
    variants: string[];
    serviceType: string;
    communicationType: string;
    scheduleOptions: string;
    date: string;
    startTime: string;
    endTime: string;
    duration: string;
    appointmentStatus: string;
    interpreterGenderRequest: string;
    cost: number;
    billableHours: number;
    requesterNotes?: string;
    internalNotes?: string;
    interpreterNotes?: string;
}

export enum DownloadFile {
    CSV  = 1,
    XLSX = 2
}

export class SelectedLanguage {
    language;
    languageVariant;
}