import { LoadDropdownDataService } from './../../../../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { Component, ViewChild } from '@angular/core';
import { FilterModel } from '../../request-list.model';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RequestListService } from '../../request-list.service';
import { SelectedLanguage } from '../../../../reports/reports.model';
import { CustomDropdownComponent } from '../../../../../shared/common-components/custom-dropdown/custom-dropdown.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent{

@ViewChild(RangeDatepickerComponent) rangeDatepickerComponent: RangeDatepickerComponent;
@ViewChild('languageDropdown') languageDropdown: CustomDropdownComponent;
filterModel       = new FilterModel();
selectedlanguage  = new SelectedLanguage();
languagesListData: any;
constructor(
  private ngbDateParserFormatter: NgbDateParserFormatter,
  public requestListService     :RequestListService,
  public activeModal            : NgbActiveModal,
  public loadDropdownDataService:LoadDropdownDataService
  ) {}

  reset(){
    this.filterModel = new FilterModel();
    this.filterModel.languageId = undefined;
    this.rangeDatepickerComponent?.clearDates();
    this.selectedlanguage = new SelectedLanguage();
    this.assignOrClearSelectedLangAndVariant();
  }

  assignOrClearSelectedLangAndVariant(){
    this.languageDropdown.variantselectedOption = this.selectedlanguage?.languageVariant;
    this.languageDropdown.selectedOption = this.selectedlanguage?.language ;
  }

  applyFilter(){
    this.activeModal.close(this.filterModel);
  }

  getSelectedDates(event){
    this.filterModel.dateRange.startDate = this.ngbDateParserFormatter.format(event.startDate);
    this.filterModel.dateRange.endDate = this.ngbDateParserFormatter.format(event.endDate);
}


addNewLanguages(event) {
  this.filterModel.languageId = event?.languageVariant != undefined ? event?.languageVariant?.id : event?.language?.languageVariant[0].id, this.selectedlanguage = event;
}

}