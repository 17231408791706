
<div class="container-fluid p-4">
    <div class="row">
      <div class="col-12">
          <div>
              <div class="d-flex align-items-center justify-content-between popUpheader">
                  <span *ngIf="userDetails?.organizations[0]?.role?.value==roles.Interpreter && this.ModalTitleFromReqList==this.modalTitle.EditAppointmentForm && !isCloseRequest" class="modalTitle">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.UPDATEAPPOINTMENTFORM' | translate}}</span>
                  <span *ngIf="(userDetails?.organizations[0]?.role?.value!=roles.Interpreter || this.ModalTitleFromReqList!=this.modalTitle.EditAppointmentForm) && !isCloseRequest" class="modalTitle">{{ModalTitleFromReqList | translate}}</span>
                  <span *ngIf="isCloseRequest" class="modalTitle">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CLOSEREQUEST' | translate}}</span>
                  <div class="d-flex align-items-center cursor-pointer gap-3">
                    <div class="d-flex" (click)="reset()" *ngIf="this.ModalTitleFromReqList==this.modalTitle.AppointmentForm && createRequestModel.appointmentCategoryId">
                      <svg class="me-1" xmlns="http://www.w3.org/2000/svg" width="13.895" height="16" viewBox="0 0 17.895 20">
                        <g id="reset-undo" transform="translate(-4 -2)">
                          <g id="Group_41566" data-name="Group 41566" transform="translate(4 2)">
                            <path id="Path_35107" data-name="Path 35107" d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z" transform="translate(-4 -2)" fill="#047bbc"/>
                          </g>
                          <g id="Group_41567" data-name="Group 41567" transform="translate(4.231 2.95)">
                            <path id="Path_35108" data-name="Path 35108" d="M12.458,22a9.2,9.2,0,0,1-3.389-.633,10.008,10.008,0,0,1-4.66-3.694A1.036,1.036,0,0,1,4.728,16.2a1.044,1.044,0,0,1,1.483.317,7.4,7.4,0,0,0,3.6,2.85,7.782,7.782,0,0,0,4.66.211,7.383,7.383,0,0,0,3.812-2.639,8,8,0,0,0,1.588-4.327,7.34,7.34,0,0,0-1.377-4.433A7.971,7.971,0,0,0,14.788,5.43a7.053,7.053,0,0,0-4.66,0A7.971,7.971,0,0,0,6.422,8.174a1.044,1.044,0,0,1-1.483.317.954.954,0,0,1-.212-1.478,9.378,9.378,0,0,1,15.461,0,9.055,9.055,0,0,1,1.694,5.7,9.05,9.05,0,0,1-2.012,5.594,10.256,10.256,0,0,1-4.977,3.377A8.517,8.517,0,0,1,12.458,22Z" transform="translate(-4.231 -2.95)" fill="#047bbc"/>
                          </g>
                        </g>
                      </svg>
                      <span class="reset-text">{{'COMMON.BUTTON.BUTTONLABEL.RESET' | translate}}</span>
                    </div>
                    <!-- cross svg -->
                    <div class="d-flex" *ngIf="userDetails.organizations[0].role?.value!=roles.Interpreter && this.ModalTitleFromReqList!=this.modalTitle.EditAppointmentForm" (click)="activeModal.close()">
                      <svg class="me-1" id="close" xmlns="http://www.w3.org/2000/svg"  width="13.895" height="16" viewBox="0 0 20 20">
                        <g id="close_1_">
                          <path id="Path_35127" data-name="Path 35127" d="M1.9,22.441a1.891,1.891,0,0,1-1.35-.46,1.93,1.93,0,0,1,0-2.7L16.7,2.989a1.89,1.89,0,0,1,2.688.09A1.931,1.931,0,0,1,19.476,5.6L3.235,21.981a1.891,1.891,0,0,1-1.331.46Z" transform="translate(-0.001 -2.449)" fill="#e0532f"/>
                          <path id="Path_35128" data-name="Path 35128" d="M19.431,21.834a1.889,1.889,0,0,1-1.329-.556L1.985,5.007a1.929,1.929,0,0,1,.207-2.7,1.885,1.885,0,0,1,2.47,0L20.873,18.576a1.929,1.929,0,0,1,.088,2.709q-.043.046-.088.089a1.887,1.887,0,0,1-1.443.46Z" transform="translate(-1.474 -1.844)" fill="#e0532f"/>
                        </g>
                      </svg>
                      <span class="close-text">{{'COMMON.BUTTON.BUTTONLABEL.CLOSE' | translate}}</span>
                    </div>
                    <div class="d-flex" *ngIf="this.ModalTitleFromReqList==this.modalTitle.EditAppointmentForm && this.visibleForm != this.showFormView.FormStart" (click)="activeModal.close()">
                      <svg class="me-1" id="close" xmlns="http://www.w3.org/2000/svg"  width="13.895" height="16" viewBox="0 0 20 20">
                        <g id="close_1_">
                          <path id="Path_35127" data-name="Path 35127" d="M1.9,22.441a1.891,1.891,0,0,1-1.35-.46,1.93,1.93,0,0,1,0-2.7L16.7,2.989a1.89,1.89,0,0,1,2.688.09A1.931,1.931,0,0,1,19.476,5.6L3.235,21.981a1.891,1.891,0,0,1-1.331.46Z" transform="translate(-0.001 -2.449)" fill="#e0532f"/>
                          <path id="Path_35128" data-name="Path 35128" d="M19.431,21.834a1.889,1.889,0,0,1-1.329-.556L1.985,5.007a1.929,1.929,0,0,1,.207-2.7,1.885,1.885,0,0,1,2.47,0L20.873,18.576a1.929,1.929,0,0,1,.088,2.709q-.043.046-.088.089a1.887,1.887,0,0,1-1.443.46Z" transform="translate(-1.474 -1.844)" fill="#e0532f"/>
                        </g>
                      </svg>
                      <span class="close-text">{{'COMMON.BUTTON.BUTTONLABEL.CLOSE' | translate}}</span>
                    </div>
                     <!-- cross svg -->
                  </div>
              </div>
              <div class="progrss py-1" *ngIf="(userDetails.organizations[0].role?.value!=roles.Interpreter && !isCloseRequest ) || this.ModalTitleFromReqList!=this.modalTitle.EditAppointmentForm">
                <div class="horizontal-line"></div>

                <!-- Circle-Start Initial-->
                <svg class="circle-start" *ngIf="createRequestModel.appointmentCategoryId == undefined" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="Ellipse_57" data-name="Ellipse 57" fill="#fff" stroke="#047bbc" stroke-width="1">
                      <circle cx="10" cy="10" r="10" stroke="none"/>
                      <circle cx="10" cy="10" r="9.5" fill="none"/>
                    </g>
                  </svg>
                <!-- Circle-Start In Progress -->
                <svg class="circle-start" *ngIf="createRequestModel.appointmentCategoryId != undefined && !isForm1Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#cce4f2" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>
                <!-- Circle-Start Completed -->
                <svg class="circle-start" *ngIf="createRequestModel.appointmentCategoryId != undefined && isForm1Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#047bbc" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>

                <!-- Circle-Middle Initial-->
                <svg class="circle-middle" *ngIf="!isForm2Valid"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#fff" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>
                <!-- Circle-Middle In Progress -->
                <svg class="circle-middle" *ngIf="isForm1Valid && !isForm2Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#cce4f2" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>
                <!-- Circle-Middle Completed -->
                <svg class="circle-middle" *ngIf="isForm1Valid && isForm2Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#047bbc" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>

                <!-- Circle-end Initial-->
                  <svg class="circle-end" *ngIf="!isForm3Valid"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="Ellipse_57" data-name="Ellipse 57" fill="#fff" stroke="#047bbc" stroke-width="1">
                      <circle cx="10" cy="10" r="10" stroke="none"/>
                      <circle cx="10" cy="10" r="9.5" fill="none"/>
                    </g>
                  </svg>
                <!-- Circle-end In Progress -->
                <svg class="circle-end" *ngIf="isForm2Valid && !isForm3Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#cce4f2" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>
                <!-- Circle-end Completed -->
                <svg class="circle-end" *ngIf="isForm2Valid && isForm3Valid" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Ellipse_57" data-name="Ellipse 57" fill="#047bbc" stroke="#047bbc" stroke-width="1">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                  </g>
                </svg>
              </div>
              <div class="modal-body px-0 py-1">
                <div class="form-group d-flex flex-wrap mb-0 mb-lg-2 focus-field">
                  <form class="col-md-6 col-lg-6 col-12  Request-Category mb-0 mb-lg-0"
                    [ngClass]="{'pe-none':visibleForm == showFormView.FormMiddle || visibleForm == showFormView.FormLast,'pe-point-75rem mb-2 ':this.ModalTitleFromReqList==this.modalTitle?.AppointmentForm }">
                    <select *ngIf="this.ModalTitleFromReqList==this.modalTitle.EditAppointmentForm" 
                      class="form-control lh-normal focus-none pe-0 ps-sm-2 ps-2 ps-md-2 py-2 f-12 b-r-5 mt-0 pe-none border border-white f-12 color-primary font-semi-bold" [value]="createRequestModel.appointmentCategoryId"
                      name="department_" [(ngModel)]="createRequestModel.appointmentCategoryId" required>
                      <option class="text-muted" *ngFor="let item of loadDropdownDataService?.commonGetDropdownModel?.requestCategories"
                        [value]="item.id">{{item.value}}
                      </option>
                    </select>
                    <app-dropdown *ngIf="this.ModalTitleFromReqList==this.modalTitle.AppointmentForm" [class]="'bigFilterDropdown'" [dynamic_class]="'filterDropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTCATEGORY' | translate"
                      [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTCATEGORY' | translate" [dropdownValue]="createRequestModel.appointmentCategoryId"
                      (dropdownValueEmit)="createRequestModel.appointmentCategoryId = $event;visibleForm = showFormView.FormStart;clearProgress()"
                      [dropDownList]="loadDropdownDataService.commonGetDropdownModel.requestCategories" [isRequired]="true"
                      [id]="'Request_Category'">
                    </app-dropdown>
                  </form>
                  <form [ngClass]=" {'ps-point-75rem ':this.ModalTitleFromReqList==this.modalTitle?.AppointmentForm }" #OrganizationForm="ngForm" class="col-md-6 col-lg-6 col-12  mb-2 mb-lg-0">
                    <app-dropdown *ngIf="this.ModalTitleFromReqList==this.modalTitle?.AppointmentForm"
                      [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTREQUESTOR&ORG' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTOR&ORG' | translate"
                      [dropdownValue]="createRequestModel.userId" (dropdownValueEmit)="createRequestModel.userId=$event;"
                      (dropdownDataEmit)="createRequestModel.organizationId = $event.organizationId"
                      [isDropdownDisabled]="((userDetails?.organizations[0]?.role?.value == roles?.Requester)&&(createRequestModel?.userId == userDetails?.id))"
                      [dropDownList]="loadDropdownDataService.commonGetDropdownModel.requestersOfOrganization" [isRequired]="true" [id]="'Requester_&_Organization'">
                    </app-dropdown>
                    <!-- <select  *ngIf="this.ModalTitleFromReqList==this.modalTitle?.EditAppointmentForm"
                      class="form-control lh-normal pe-0 ps-sm-2 ps-2 ps-md-3 py-2 f-12 text-truncate mt-0 pe-none border border-white f-12 color-primary-grey font-semi-bold" [value]="createRequestModel?.userId"
                      name="Requester_&_Organization_1" [(ngModel)]="createRequestModel.userId" required>
                      <option class="text-muted" *ngFor="let item of loadDropdownDataService?.commonGetDropdownModel?.requestersOfOrganization"
                        [value]="item?.id" (click)="createRequestModel.organizationId = item?.organizationId">{{item?.value}}
                      </option>
                    </select> -->
                    <span *ngIf="this.ModalTitleFromReqList==this.modalTitle?.EditAppointmentForm"
                      class="lh-normal d-block pe-0 ps-sm-2 ps-2 ps-md-3 py-2 f-12 organization_user text-truncate mt-0 pe-none border border-white f-12 color-primary-grey font-semi-bold" 
                      name="Requester_&_Organization_1">
                      {{this.editAppointmentListData?.user.value }} | {{this.editAppointmentListData?.organization?.value}}
                    </span>
                  </form>
                </div>
                <!-- Form 1-->
                <form *ngIf="visibleForm == showFormView?.FormStart && (userDetails?.organizations[0]?.role?.value != roles?.Interpreter && !isCloseRequest)"
                   ngNativeValidate
                  #CreateRequestForm1="ngForm">
                  <div class="theme-form height-496px overflow-y-auto overflow-x-hidden justify-content-between d-flex flex-column" >
                    <!--Interpreter Requestor Form 1-->
                    <div
                      *ngIf="createRequestModel?.appointmentCategoryId == requestCategory?.InterpretationRequest && visibleForm == showFormView?.FormStart">
                      <div class="form-group d-flex flex-wrap mb-2" >
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-date-single [item]="'hg'"
                            [fromDateFromParent]="createRequestModel?.timeDuration?.date"  
                            (selectedDates)="getSelectedDates($event);validateTimeInput();"
                            [datePickerLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.DATE' | translate" 
                            [isRequired]="true"
                            [minDate]="currentData"
                            [datepickername]="'customDatePicker2'"></app-date-single>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12 ps-point-75rem d-flex">
                          <div class="col-6 pe-1">
                            <app-time-picker
                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STARTTIME' | translate"  
                            [id]="'startTimes'" 
                            [name]="'startTimes'"
                            [isRequired]="true"
                            [isInvalid]="timeInvalid"
                            [timePickerValue]="createRequestModel?.timeDuration?.startTime"
                            (timePickerValueEmit)="createRequestModel?.timeDuration.startTime=$event;selectSheduledOption() ;validateTimeInput();"
                            >
                            </app-time-picker>
                          </div>
                          <div class="col-6 position-relative ps-1">
                            <app-time-picker
                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDTIME' | translate" 
                            [id]="'endTimes'" 
                            [name]="'endTimes'"
                            [isRequired]="true"
                            [isInvalid]="timeInvalid"
                            [isTextTruncate]="true"
                            [timePickerValue]="createRequestModel?.timeDuration?.endTime"
                            (timePickerValueEmit)="createRequestModel?.timeDuration.endTime=$event;validateTimeInput()"
                            >
                            </app-time-picker>
                             <!-- Time picker Info Icon -->
                          <svg [ngClass]="{'stroke-red':timeInvalid}" [container]="'body'" [ngbTooltip]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.TIME.INFO' | translate" class="position-absolute cursor-pointer infoIcon-custom-style" placement="right" id="info" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="1 -1 12 15">
                            <g id="Group_1582" data-name="Group 1582">
                              <g  id="Group_1581" data-name="Group 1581">
                                <path id="Path_1018" data-name="Path 1018"
                                  d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM6,11.163A5.163,5.163,0,1,1,11.163,6,5.169,5.169,0,0,1,6,11.163Z"
                                  fill="#b9b9b9" />
                              </g>
                            </g>
                            <g  id="Group_1584" data-name="Group 1584" transform="translate(5.426 5.126)">
                              <g  id="Group_1583" data-name="Group 1583">
                                <path  id="Path_1019" data-name="Path 1019"
                                  d="M230.634,213.4c-.336,0-.575.142-.575.351v2.844c0,.179.239.358.575.358s.582-.179.582-.358v-2.844C231.216,213.539,230.955,213.4,230.634,213.4Z"
                                  transform="translate(-230.059 -213.397)" fill="#b9b9b9" />
                              </g>
                            </g>
                            <g  id="Group_1586" data-name="Group 1586" transform="translate(5.388 3.163)">
                              <g  id="Group_1585" data-name="Group 1585">
                                <path id="Path_1020" data-name="Path 1020"
                                  d="M228.987,134.208a.538.538,0,1,0,.6.53A.578.578,0,0,0,228.987,134.208Z"
                                  transform="translate(-228.375 -134.208)" fill="#b9b9b9" />
                              </g>
                            </g>
                          </svg>
                          <!-- Time picker Info Icon end -->
                          </div>
                         
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-0 mb-lg-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0 HideLookIcon"> 
                        <app-dropdown 
                            [isDiscription] = "true"
                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SCHEDULEOPTION' | translate" 
                            [dropdownValue]="createRequestModel?.scheduleOptionsId"
                            (dropdownValueEmit)="createRequestModel.scheduleOptionsId= $event"
                            [dropDownList]="loadDropdownDataService?.commonGetDropdownModel?.scheduleOptions"
                            [id]="'Schedule_Options'"
                            [isRequired]="true"
                            [isDropdownDisabled] = "true"
                            >
                          </app-dropdown>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12 ps-point-75rem mb-2 mb-lg-0">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTCOMMUNICATIONTYPE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.COMMUNICATIONTYPE' | translate"
                            [dropdownValue]="createRequestModel.communicationTypeId"
                            (dropdownValueEmit)="createRequestModel.communicationTypeId = $event ;validateTimeInput();validateLink(); (createRequestModel.communicationTypeId != communicationTypes.VideoCall) ? createRequestModel.link = null :''"
                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.communicationTypes" [isRequired]="true"
                            (dropdownValueEmitForName)="communicationTypeName = $event"
                            [id]="'Communication_Type'"></app-dropdown>
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2
                      ">
                        <div
                          *ngIf="(communicationTypeName == communicationType.PhoneCall || communicationTypeName == communicationType.VideoCall) || (communicationTypeName == communicationType.EsPhoneCall || communicationTypeName == communicationType.EsVideoCall)" 
                          class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTSTATE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STATE' | translate" 
                          [dropdownValue]="createRequestModel?.location?.stateId" 
                          (dropdownValueEmit)="createRequestModel?.location.stateId=$event" 
                          [dropDownList]="loadDropdownDataService.commonGetDropdownModel.states" [isRequired]="true"
                          [id]="'State'"></app-dropdown>
                        </div>
                        <div 
                          *ngIf="(communicationTypeName == communicationType.VideoCall || communicationTypeName == communicationType.EsVideoCall)"
                           class="col-md-6 col-lg-6 col-12 ps-point-75rem mb-2 mb-xl-0">

                          <app-input
                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK' | translate" 
                            [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERCALLLINK' | translate" 
                            [infoIconVisible]="true" [ngToolTipContent]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.CALLLINK.TOOLTIP'" 
                            [ngClass]="{'call-link-error-border-red': validLink}"
                            [svgNgClass]="{'call-link-info-icon-stroke-red': validLink}"
                            [inputValue]="createRequestModel?.link" (inputValueEmit)="createRequestModel.link=$event;validateLink()"
                            [inputType]="'text'" [isRequired]="true" [id]="'link'" 
                            [name]="'link'">
                          </app-input>
                        </div>
                        <div
                          *ngIf="(communicationTypeName == communicationType.PhoneCall || communicationTypeName == communicationType.EsPhoneCall)"
                          class="col-md-6 col-lg-6 col-12 mb-2 mb-xl-0" [ngClass]="((communicationTypeName == communicationType.PhoneCall) || (communicationTypeName == communicationType.EsPhoneCall)) ? 'ps-point-75rem':'pe-point-75rem'">
                          <app-input 
                          [form]="CreateRequestForm1" [isCountryCodeDropdown]="true"  [dropdownValue]="createRequestModel?.phoneCodeId" (dropdownValueEmit)="createRequestModel.phoneCodeId = $event"  [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLINNUMBER' | translate"  [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERCALLINNUMBER' | translate" 
                            [inputValue]="createRequestModel?.phoneNumber" (inputValueEmit)="createRequestModel.phoneNumber=$event" 
                            [inputType]="'text'" [isRequired]="true" [id]="'contact_number'"
                            [name]="'contact_number'" ></app-input>
                        </div>
                        <div
                          *ngIf="(communicationTypeName == communicationType.VideoCall || communicationTypeName == communicationType.EsVideoCall)"
                          class="col-md-6 col-lg-6 col-12 mb-2 mb-xl-0" [ngClass]="communicationTypeName == communicationType.PhoneCall ? 'ps-point-75rem':'pe-point-75rem'">
                          <app-input 
                          [form]="CreateRequestForm1"
                          [isCountryCodeDropdown]="true"  [dropdownValue]="createRequestModel?.phoneCodeId" (dropdownValueEmit)="createRequestModel.phoneCodeId = $event" [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLINNUMBER' | translate"  [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERCALLINNUMBER' | translate" 
                            [inputValue]="createRequestModel?.phoneNumber" (inputValueEmit)="createRequestModel.phoneNumber=$event" 
                            [isRequired]="false" [id]="'contact_number'"
                            [name]="'contact_number'"></app-input>
                        </div>
                        <div
                          *ngIf="(communicationTypeName == communicationType.VideoCall || communicationTypeName == communicationType.EsVideoCall)"
                          class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                          <app-input onlyNumbers [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ACCESSCODE' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERACCESSCODE' | translate" 
                            [inputValue]="createRequestModel?.accessCode" (inputValueEmit)="createRequestModel.accessCode=$event"
                            [inputType]="'text'" [isRequired]="false" [id]="'Access_Code'"
                            [name]="'Access_Code'" maxlength="10"></app-input>
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-0
                      " *ngIf="(communicationTypeName == communicationType.PhoneCall || communicationTypeName == communicationType.VideoCall) || (communicationTypeName == communicationType.EsPhoneCall || communicationTypeName == communicationType.EsVideoCall)">
                        <div
                          class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2">
                          <app-input onlyNumbers [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LOCATION' | translate" 
                            [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERZIPCODEREQUESTOR' | translate" 
                            [inputValue]="createRequestModel?.location?.zipCode"
                            (inputValueEmit)="createRequestModel?.location.zipCode=$event" 
                            [inputType]="'text'" [isRequired]="true" [id]="'location'"
                            [name]="'location'" maxlength="5">
                          </app-input>
                        </div>
                      </div>

                      <div *ngIf="communicationTypeName == communicationType.InPerson || communicationTypeName == communicationType.EsInPerson">
                        <div class="form-group d-flex flex-wrap mb-2">
                           <div class="col-md-12 col-lg-12 col-12 mb-2">
                            <app-input  [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.NAMEOFLOCATION' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERLOCATION' | translate" 
                            [inputValue]="createRequestModel?.location.nameOfLocation" (inputValueEmit)="createRequestModel.location.nameOfLocation=$event"
                            [inputType]="'text'" [isRequired]="false" [id]="'Landmark'"
                            [name]="'Location'"></app-input>
                          </div>
                          <div 
                            class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-xl-0">
                            <app-input [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ADDRESSLINE1' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERADDRESS1' | translate" 
                            [inputValue]="createRequestModel?.location?.addressLine1" (inputValueEmit)="createRequestModel?.location.addressLine1=$event"
                              [inputType]="'text'" [isRequired]="true" [id]="'Address_Line_1'"
                              [name]="'Address_Line_1'"></app-input>
                          </div>
                          <div class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                            <app-input [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ADDRESSLINE2' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERADDRESS2' | translate" 
                            [inputValue]="createRequestModel?.location?.addressLine2" (inputValueEmit)="createRequestModel?.location.addressLine2=$event"
                            [inputType]="'text'" [id]="'Address_Line_2'"
                              [name]="'Address_Line_2'"></app-input>
                          </div>
                          <!-- <div class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                            <app-input [inputLabel]="'Landmark'" [inputPlaceholder]="'Enter nearby landmark'" [inputValue]=""
                              (inputValueEmit)="$event" [inputType]="'text'" [isRequired]="false" [id]="'Landmark'"
                              [name]="'Landmark'"></app-input>
                          </div> -->
                        </div>
                        <div class="form-group d-flex flex-wrap mb-2">
                          <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-xl-0">
                            <app-input onlyAlphabets [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CITY' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERCITY' | translate" 
                            [inputValue]="createRequestModel?.location?.city" (inputValueEmit)="createRequestModel?.location.city=$event"
                            [inputType]="'text'" [isRequired]="true" [id]="'City'"
                              [name]="'City'"></app-input>
                          </div>
                          <div class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                            <app-input onlyNumbers [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ZIPCODE' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERZIPCODE' | translate" 
                            [inputValue]="createRequestModel?.location?.zipCode" (inputValueEmit)="createRequestModel?.location.zipCode=$event"
                            [inputType]="'text'" [isRequired]="true" [id]="'Zip_code'"
                              [name]="'Zip_code'" maxlength="5"></app-input>
                          </div>
                        </div>
                        <div class="form-group d-flex flex-wrap mb-2">
                          <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                            <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTSTATE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STATE' | translate" 
                              [dropdownValue]="createRequestModel?.location?.stateId" 
                              (dropdownValueEmit)="createRequestModel?.location.stateId=$event" 
                              [dropDownList]="loadDropdownDataService.commonGetDropdownModel.states" [isRequired]="true"
                              [id]="'State'"></app-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
              
                    <!--Linguistic Match Call  Form 1-->
                    <div
                      *ngIf="createRequestModel.appointmentCategoryId == requestCategory.LinguisticMatchCall && visibleForm == showFormView.FormStart">
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-date-single
                          [fromDateFromParent]="createRequestModel?.timeDuration?.date"  
                          (selectedDates)="getSelectedDates($event)"
                          [datePickerLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.DATE' | translate" 
                          [isRequired]="true"
                          [datepickername]="'customDatePicker1'"
                          ></app-date-single>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12 ps-point-75rem d-flex">
                          <div class="col-6 pe-1">

                            <app-time-picker
                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STARTTIME' | translate" 
                            [id]="'startTimes'" 
                            [name]="'startTimes'"
                            [isRequired]="true"
                            [timePickerValue]="createRequestModel?.timeDuration?.startTime"
                            (timePickerValueEmit)="createRequestModel?.timeDuration.startTime=$event;"
                            >
                            </app-time-picker>
                            <!-- setMinTime(createRequestModel?.timeDuration?.startTime,0) not used so removed -->
                            <!-- [minTime]="setMinTime(createRequestModel?.timeDuration?.startTime,0)" -->
                            <!-- <app-input 
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STARTTIME' | translate" 
                              [inputType]="'time'" 
                              [id]="'startTime'" 
                              [name]="'startTime'"
                              [isRequired]="true"
                              [inputValue]="createRequestModel?.timeDuration?.startTime"
                              (inputValueEmit)="createRequestModel?.timeDuration.startTime=$event"
                            >
                            </app-input> -->
                          </div>
                          <!-- <div class="col-6 ps-1">
                              <app-input 
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDTIME' | translate" 
                              [inputType]="'time'" 
                              [id]="'endTime'" 
                              [name]="'endTime'"
                              [isRequired]="true"
                              [isRequired]="true"
                              [inputValue]="createRequestModel?.timeDuration?.endTime"
                              (inputValueEmit)="createRequestModel?.timeDuration.endTime=$event">
                              </app-input>
                          </div>  -->
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTSTATE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STATE' | translate" 
                            [dropdownValue]="createRequestModel?.location?.stateId" 
                            (dropdownValueEmit)="createRequestModel?.location.stateId=$event" 
                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.states" [isRequired]="true"
                            [id]="'State'"></app-dropdown>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12 mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0 ps-point-75rem">
                          <app-input [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK/NUMBER' | translate" [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.CALLINNUMBER/LINK' | translate" 
                            [inputValue]="createRequestModel?.phoneNumber" (inputValueEmit)="createRequestModel.phoneNumber=$event;validateLink()"
                            [inputType]="'text'" [id]="'Call-In_Number/Link'"
                            [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERCALLLINK' | translate" 
                            [infoIconVisible]="true" [ngToolTipContent]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.CALLLINK.NUMBER.TOOLTIP'" 
                            [ngClass]="{'call-link-error-border-red': validLink}"
                            [svgNgClass]="{'call-link-info-icon-stroke-red': validLink}"
                            [name]="'Call-In_Number/Link'"></app-input>
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2">
                          <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-xl-0">
                            <app-input onlyNumbers [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LOCATION' | translate" 
                            [inputPlaceholder]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.ENTERZIPCODEREQUESTOR' | translate" 
                            [inputValue]="createRequestModel?.location?.zipCode"
                            (inputValueEmit)="createRequestModel?.location.zipCode=$event" 
                            [inputType]="'text'" [isRequired]="true" [id]="'location'"
                            [name]="'location'" maxlength="5">
                          </app-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row py-2">
                    <div *ngIf="this.ModalTitleFromReqList==this.modalTitle.AppointmentForm" class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
                      <app-button [buttonLabel]="(createRequestModel.appointmentCategoryId != undefined)?'COMMON.BUTTON.BUTTONLABEL.BACK':'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="clearForm()"
                        [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'button'" [id]="'user_cancel_button'"
                        [name]="'user_cancel_button'"></app-button>
                    </div>
                    <div *ngIf="this.ModalTitleFromReqList==this.modalTitle.EditAppointmentForm" class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
                      <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="activeModal.close()"
                        [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'button'" [id]="'user_cancel_button'"
                        [name]="'user_cancel_button'"></app-button>
                    </div>
                    <div 
                      class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
                      <app-button [buttonLabel]="'COMMON.PAGINATION.NEXT' | translate" (buttonOutputEvent)="onHideForm1()?visibleForm = showFormView.FormMiddle:null"
                        [buttonClass]="CreateRequestForm1?.valid && createRequestModel.appointmentCategoryId && !timeInvalid && !validLink ? 'button-primary buttonBig w-65px':'button-disabled buttonBig w-65px'"
                        [buttonType]="'submit'" [id]="'Next_button'" [name]="'Next_button'"></app-button>
                    </div>
                  </div>
                </form>
              
                <!--Form 2-->
                <form *ngIf="visibleForm == showFormView.FormMiddle"
                   ngNativeValidate
                  #CreateRequestForm2="ngForm">
                  <div class="theme-form height-496px overflow-y-auto overflow-x-hidden justify-content-between d-flex flex-column" >
                    <!--Interpreter Requestor Form 2-->
                    <div
                      *ngIf="createRequestModel.appointmentCategoryId == requestCategory.InterpretationRequest && visibleForm == showFormView.FormMiddle">
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTSERVICETYPE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SERVICETYPE' | translate"
                            [dropdownValue]="createRequestModel?.serviceId" 
                            (dropdownValueEmit)="createRequestModel.serviceId=$event" 
                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.services"
                            [isRequired]="true" [id]="'Service_Type'"></app-dropdown>
                        </div>
                      </div>
                      <label class="red-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.PREFFERLANGPAIR' | translate}}</label>
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.FROM' | translate" 
                          [dropdownValue]="preferedfromValue" 
                          (dropdownValueEmit)="preferedfromValue=$event;acceptableFromValue=$event;getVariant($event,'PreferredFrom');!preferedfromValue ? PreferredFromLangVariantDropdown?.setCurrentSelection({}) : null ;!preferedfromValue ? AcceptableFromLangVariantDropdown?.setCurrentSelection({}) : null ; "
                          [dropDownList]="languageList" [isRequired]="true"
                          [optionDisable]="preferedToValue"
                          [id]="'Preferred_Lang_From'"></app-dropdown>
                        </div>
                        <div class="col-md-6 hide-preffered_acceptable-dropdown col-lg-6 col-12 ps-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.TO' | translate" 
                            [dropdownValue]="preferedToValue" 
                            (dropdownValueEmit)="preferedToValue=$event;getVariant($event,'PreferredTo');!preferedToValue? PreferredToLangVariantDropdown?.setCurrentSelection({}):null;"
                            [dropDownList]="languageList" [isRequired]="true"
                            [optionDisable]="preferedfromValue"
                            [id]="'Preferred_Lang_To'"></app-dropdown>
                        </div>
                        <!--Preferred_Language_Variant_1 Display in place of Preferred_Lang_To when < 767px  -->
                        <ng-container>
                          <div class="col-md-6 show-preffered_acceptable-dropdown d-none col-lg-6 col-12 ps-point-75rem">
                            <app-dropdown #PreferredFromLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                              [dropdownValue]="appointmentPreferredLanguage?.fromLangVariantId" 
                              (dropdownValueEmit)="appointmentPreferredLanguage.fromLangVariantId=$event" 
                              [dropDownList]="preferredFromVariantList" [isRequired]="true"
                              [id]="'Preferred_Language_Variant_1'">
                            </app-dropdown>
                          </div>
                        </ng-container>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2 Request-Category">
                        <div class="col-md-6 hide-preffered_acceptable-dropdown col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-dropdown #PreferredFromLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                            [dropdownValue]="appointmentPreferredLanguage?.fromLangVariantId" 
                            (dropdownValueEmit)="appointmentPreferredLanguage.fromLangVariantId=$event" 
                            [dropDownList]="preferredFromVariantList" [isRequired]="true"
                            [id]="'Preferred_Language_Variant_1'">
                          </app-dropdown>
                        </div>
                        <!--Preferred_Lang_To Display in place of Preferred_Language_Variant_1 when < 767px  -->
                        <ng-container>
                          <div class="col-md-6 show-preffered_acceptable-dropdown d-none lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                            <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.TO' | translate" 
                              [dropdownValue]="preferedToValue" 
                              (dropdownValueEmit)="preferedToValue=$event;getVariant($event,'PreferredTo');!preferedToValue? PreferredToLangVariantDropdown?.setCurrentSelection({}):null;"
                              [dropDownList]="languageList" [isRequired]="true"
                              [optionDisable]="preferedfromValue"
                              [id]="'Preferred_Lang_To'">
                            </app-dropdown>
                          </div>
                        </ng-container>
                        <div class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                          <app-dropdown #PreferredToLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                            [dropdownValue]="appointmentPreferredLanguage?.toLangVariantId" 
                            (dropdownValueEmit)="appointmentPreferredLanguage.toLangVariantId=$event" 
                            [dropDownList]="preferredToVariantList" [isRequired]="true"
                            [id]="'Preferred_Language_Variant_2'"></app-dropdown>
                        </div>
                      </div>
                      <label class="red-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ACCPTABLELANGPAIR' | translate}}</label>
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.FROM' | translate" 
                            [dropdownValue]="acceptableFromValue" 
                            [isDropdownDisabled]="true"
                            (dropdownValueEmit)="acceptableFromValue=$event;getVariant($event,'AcceptableFrom')" 
                            [disabledOptions]="true"
                            [dropDownList]="languageList" [isRequired]="false"
                            [optionDisable]="acceptableToValue"
                            [id]="'Acceptable_Lang_From'"></app-dropdown>
                        </div>
                        <div class="col-md-6 col-lg-6 hide-preffered_acceptable-dropdown col-12 ps-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.TO' | translate" 
                            [dropdownValue]="acceptableToValue" 
                            (dropdownValueEmit)="acceptableToValue=$event;getVariant($event,'AcceptableTo');!acceptableToValue ? AcceptableToLangVariantDropdown?.setCurrentSelection({}):null;"
                            [optionDisable]="acceptableFromValue"
                            [dropDownList]="languageList" [isRequired]="false"
                            [id]="'Acceptable_Lang_To'"></app-dropdown>
                        </div>
                        <!--Acceptable_Language_Variant_1 Display in place of Acceptable_Lang_To when < 767px  -->
                        <ng-container>
                          <div class="col-md-6 show-preffered_acceptable-dropdown d-none col-lg-6 col-12 ps-point-75rem">
                            <app-dropdown #AcceptableFromLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                            [dropdownValue]="appointmentAcceptableLanguage?.fromLangVariantId" 
                            (dropdownValueEmit)="appointmentAcceptableLanguage.fromLangVariantId=$event" 
                            [dropDownList]="acceptableFromVariantList" [isRequired]="appointmentAcceptableLanguage?.toLangVariantId"
                            [id]="'Acceptable_Language_Variant_1'">
                          </app-dropdown>
                          </div>
                        </ng-container>
                      </div>
                      <div class="form-group d-flex flex-wrap  mb-2 Request-Category">
                        <div class="col-md-6 col-lg-6 hide-preffered_acceptable-dropdown col-12 pe-point-75rem mb-2 mb-lg-0">
                          <app-dropdown #AcceptableFromLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                            [dropdownValue]="appointmentAcceptableLanguage?.fromLangVariantId" 
                            (dropdownValueEmit)="appointmentAcceptableLanguage.fromLangVariantId=$event" 
                            [dropDownList]="acceptableFromVariantList" [isRequired]="appointmentAcceptableLanguage?.toLangVariantId"
                            [id]="'Acceptable_Language_Variant_1'"></app-dropdown>
                        </div>
                        <!--Acceptable_Lang_To  Display in place of Acceptable_Language_Variant_1 when < 767px  -->
                        <ng-container>
                          <div class="col-md-6 show-preffered_acceptable-dropdown d-none mb-2 col-lg-6 col-12 ps-point-75rem">
                            <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTLANGUAGE' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.TO' | translate" 
                            [dropdownValue]="acceptableToValue" 
                            (dropdownValueEmit)="acceptableToValue=$event;getVariant($event,'AcceptableTo');!acceptableToValue ? AcceptableToLangVariantDropdown?.setCurrentSelection({}):null;"
                            [optionDisable]="acceptableFromValue"
                            [dropDownList]="languageList" [isRequired]="false"
                            [id]="'Acceptable_Lang_To'">
                          </app-dropdown>
                          </div>
                        </ng-container>
                        <div class="col-md-6 col-lg-6 col-12 ps-point-75rem">
                          <app-dropdown #AcceptableToLangVariantDropdown [dynamic_class]="'variant-dropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTVARIENT' | translate" [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT' | translate"
                            [dropdownValue]="appointmentAcceptableLanguage?.toLangVariantId" 
                            (dropdownValueEmit)="appointmentAcceptableLanguage.toLangVariantId=$event" 
                            [dropDownList]="acceptableToVariantList" [isRequired]="appointmentAcceptableLanguage?.fromLangVariantId"
                            [id]="'Acceptable_Language_Variant_2'"></app-dropdown>
                        </div>
                      </div>
              
                    </div>
              
                    <!--Linguistic Match Call Form 2-->
                    <div
                      *ngIf="createRequestModel.appointmentCategoryId == requestCategory.LinguisticMatchCall && visibleForm == showFormView.FormMiddle">
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                          <app-input [onlyAlphabets]="true" [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.LANGUAGE' | translate" [inputPlaceholder]="''" [inputValue]="createRequestModel?.matchCallLanguages?.language" (inputValueEmit)="createRequestModel?.matchCallLanguages.language=$event"
                            [inputType]="'text'" [isRequired]="true" [id]="'language'" [name]="'language'"></app-input>
                        </div>
                      </div>
                      <div class="p-0 m-0 langDiv-max-height">
                        <div class="form-group d-flex flex-wrap mb-2" *ngFor="let item of languageVariants let i = index">
                          <div class="col-md-6 col-lg-6 col-12 pe-point-75rem mb-2 mb-lg-0">
                            <app-input [onlyAlphabets]="true" [inputLabel]="('APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.LANGVARIENT'| translate)+' '+(i+1)" [inputPlaceholder]="''" 
                            [inputValue]="item.variant" (inputValueEmit)="item.variant=$event" [inputType]="'text'" [isRequired]="false"
                              [id]="'Language_Variant_1'+i" [name]="'Language_Variant_1'+i"></app-input>
                          </div>
                          <div class="col-md-6 col-lg-6 col-12 ps-point-75rem flex-column d-flex position-relative">
                            <svg class="dot" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                              <circle id="Ellipse_50" data-name="Ellipse 50" cx="5" cy="5" r="5" fill="#047bbc" />
                            </svg>
                            <label [for]="'Variant_1_Description'" class="form-label">{{('APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.VARIENT' | translate)+' '+(i+1)+' '+('APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.DISCRIPTION' | translate)}}</label>
                            <textarea class="textarea-notes" [(ngModel)]="item.description" [name]="'Variant_1_Description'+i" [id]="'Variant_1_Description'+i"
                              rows="3"
                              ></textarea>
                          </div>
                        </div>
                      </div>
                      <p class="text-center" *ngIf="languageVariants?.length!=5"><span class="addLanguageVariant f-12" [ngClass]="{'pe-none': shouldDisableAddVariant()}"
                          (click)="shouldDisableAddVariant() ? null : addLanguageVariant()">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ADDLANGVARIENT' | translate}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="form-group row py-2">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
                      <app-button [buttonLabel]="this.isForm1Valid?'COMMON.BUTTON.BUTTONLABEL.BACK':'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="showPreviousForm(showFormView.FormMiddle)"
                        [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'submit'" [id]="'user_cancel_button'"
                        [name]="'user_cancel_button'"></app-button>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
                      <app-button [buttonLabel]="'COMMON.PAGINATION.NEXT' | translate" (click)="onHideForm2()?visibleForm = showFormView.FormLast:null"
                        [buttonClass]="CreateRequestForm2?.valid?'button-primary buttonBig w-65px':'button-disabled buttonBig w-65px'"
                        [buttonType]="'submit'" [id]="'Next_button'" [name]="'Next_button'"></app-button>
                    </div>
                  </div>
                </form>
              
                <!--Form 3-->
                <form *ngIf="visibleForm == showFormView.FormLast "
                  ngNativeValidate
                  #CreateRequestForm3="ngForm">
                  <div  class="theme-form height-496px overflow-y-auto overflow-x-hidden justify-content-between d-flex flex-column" >
                    <!--Interpreter Requestor Form 3-->
                    <div
                      *ngIf="createRequestModel.appointmentCategoryId == requestCategory.InterpretationRequest && visibleForm == showFormView.FormLast">
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTPREFFEREDGENDER' | translate"
                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.INTERPRETERGENDERREQUEST' | translate" 
                            [dropdownValue]="createRequestModel?.interpreterGenderRequestId" 
                            (dropdownValueEmit)="createRequestModel.interpreterGenderRequestId=$event"
                            [dropDownList]="genderDropdownList" [isRequired]="true"
                            [id]="'Interpreter_Gender_Request'"></app-dropdown>
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-12 col-lg-12 col-12 flex-column d-flex">
                          <label for="Requester notes" class="form-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTNOTES' | translate}}</label>
                          <textarea id="Requester_notes" maxlength="1000"   class="textarea-notes" name="Requester_notes" rows="3" 
                          [(ngModel)]="createRequestModel.requesterNote"
                          [ngClass]="{'bg-disabled':!(userDetails?.organizations[0]?.role?.value==roles.Requester || userDetails?.organizations[0]?.role?.value==roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.SuperAdmin || userDetails?.organizations[0]?.role?.value==roles.Admin)}"
                          [disabled]="!(userDetails?.organizations[0]?.role?.value == roles.Requester || userDetails?.organizations[0]?.role?.value==roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.SuperAdmin || userDetails?.organizations[0]?.role?.value==roles.Admin)"></textarea>


                        </div>
                      </div>
                      <div [ngClass]="(userDetails?.organizations[0]?.role?.value == roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.Requester || (this.ModalTitleFromReqList==this.modalTitle.AppointmentForm) ) ?'d-none':''" class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-12 col-lg-12 col-12 flex-column d-flex">
                          <label for="Internal notes" class="form-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.INTERNALNOTES' | translate}}</label>
                          <textarea id="Internal_notes" class="textarea-notes"  maxlength="1000" name="Internal_notes" rows="3" [(ngModel)]="createRequestModel.internalNote"
                           ></textarea>
                        </div>
                      </div>
                    </div>
              
                    <!--Linguistic Match Call Form 3-->
                    <div
                      *ngIf="createRequestModel.appointmentCategoryId == requestCategory.LinguisticMatchCall && visibleForm == showFormView.FormLast">
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-6 col-lg-6 col-12 pe-point-75rem">
                          <app-dropdown [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTPREFFEREDGENDER' | translate"
                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.INTERPRETERGENDERREQUEST' | translate" 
                            [dropdownValue]="createRequestModel?.interpreterGenderRequestId" 
                            (dropdownValueEmit)="createRequestModel.interpreterGenderRequestId=$event"
                            [dropDownList]="genderDropdownList" [isRequired]="false"
                            [id]="'Interpreter_Gender_Request'"></app-dropdown>
                        </div>
                      </div>
                      <div class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-12 col-lg-12 col-12 flex-column d-flex">
                          <label for="Requester notes" class="form-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTNOTES' | translate}}</label>
                         
                          <textarea id="Requester_notes"  class="textarea-notes" name="Requester_notes" rows="3" 
                          [(ngModel)]="createRequestModel.requesterNote"
                          [ngClass]="{'bg-disabled':!(userDetails?.organizations[0]?.role?.value==roles.Requester || userDetails?.organizations[0]?.role?.value==roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.SuperAdmin || userDetails?.organizations[0]?.role?.value==roles.Admin)}"
                          [disabled]="!(userDetails?.organizations[0]?.role?.value==roles.Requester || userDetails?.organizations[0]?.role?.value==roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.SuperAdmin || userDetails?.organizations[0]?.role?.value==roles.Admin)"></textarea>
                        </div>
                      </div>
                      <div [ngClass]="(userDetails?.organizations[0]?.role?.value == roles.ClientAdmin || userDetails?.organizations[0]?.role?.value==roles.Requester || (this.ModalTitleFromReqList==this.modalTitle.AppointmentForm))?'d-none':''" class="form-group d-flex flex-wrap mb-2">
                        <div class="col-md-12 col-lg-12 col-12 flex-column d-flex">
                          <label for="Internal notes" class="form-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.INTERNALNOTES' | translate}}</label>
                          <textarea id="Internal_notes" class="textarea-notes" name="Internal_notes" rows="3" [(ngModel)]="createRequestModel.internalNote"
                           ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row py-2">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
                      <app-button [buttonLabel]="this.isForm2Valid?'COMMON.BUTTON.BUTTONLABEL.BACK':'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="showPreviousForm(showFormView.FormLast)"
                        [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'button'" [id]="'user_cancel_button'"
                        [name]="'user_cancel_button'"></app-button>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
                      <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.SUBMIT'" (click)="onHideForm3(OrganizationForm?.valid)"
                        [buttonClass]="CreateRequestForm3?.valid && OrganizationForm?.valid?'button-primary buttonBig w-65px':'button-disabled buttonBig w-65px'"
                        [buttonType]="'submit'" [id]="'Next_button'" [name]="'Next_button'"></app-button>
                    </div>
                  </div>
                </form>

                <!-- Update Appointment form  -->
                <form *ngIf="(userDetails?.organizations[0]?.role?.value == roles.Interpreter || (( userDetails?.organizations[0]?.role?.value == roles.SuperAdmin || userDetails?.organizations[0]?.role?.value == roles.Admin ) && isCloseRequest)) && this.ModalTitleFromReqList==this.modalTitle?.EditAppointmentForm"  
                    action=""  ngNativeValidate
                  #CreateRequestForm4="ngForm">
                  <div class="theme-form height-496px overflow-y-auto overflow-x-hidden  d-flex flex-column" >
                      <div class="form-group d-flex flex-wrap mb-2">
                          <div class="col-md-6  col-lg-6 col-12 pe-point-75rem">
                            <!-- <app-input 
                              [inputDisabled]="true"
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTENDTIME' | translate" 
                              [inputType]="'time'" 
                              [id]="'Requested_End_Time'" 
                              [name]="'Requested_End_Time'"
                              [isRequired]="true"
                              [inputValue]="createRequestModel?.timeDuration?.endTime"
                              (inputValueEmit)="createRequestModel?.timeDuration.endTime=$event"
                            > 
                            </app-input> -->
                            <app-time-picker
                              [inputDisabled]="true"
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.REQUESTENDTIME' | translate"
                              [inputType]="'time'" 
                              [id]="'Requested_End_Time'" 
                              [name]="'Requested_End_Time'"
                              [timePickerValue]="createRequestModel?.timeDuration?.endTime"
                              (timePickerValueEmit)="createRequestModel?.actualEndTime?.endTime"
                              >
                            </app-time-picker>
                          </div>
                          <div class="col-md-6 mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0 col-lg-6 col-12 ps-point-75rem">
                              <!-- <app-input 
                              [inputDisabled]="currentlyChecked==check_box_type.ENDEDLATE?false:true"
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ACTUALENDTIME' | translate" 
                              [inputType]="'time'" 
                              [id]="'Actual_End_Time'" 
                              [name]="'Actual_End_Time'"
                              [isRequired]="true"
                              [inputValue]="createRequestModel?.timeDuration?.endTime"
                              (inputValueEmit)="createRequestModel?.timeDuration?.endTime">
                              </app-input> -->
                              <app-time-picker
                              [inputDisabled]="!checkReasonForCancellation(check_box_type.ENDEDLATE)"
                              [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ACTUALENDTIME' | translate"
                              [inputType]="'time'" 
                              [id]="'Actual_End_Time'" 
                              [name]="'Actual_End_Time'"
                              [isRequired]="checkReasonForCancellation(check_box_type.ENDEDLATE)"
                              [timePickerValue]="currentFormattedTime"
                              (timePickerValueEmit)="updateTime($event)" 
                              >
                            </app-time-picker>
                            <!-- [inputDisabled]="currentlyChecked==check_box_type.ENDEDLATE?false:true" -->
                                    <!-- (timePickerValueEmit)="createRequestModel?.timeDuration?.endTime" -->
                                    <!-- [timePickerValue]="createRequestModel?.timeDuration?.endTime" -->
                          </div>
                      </div>
                      <div class="col-6 my-3">
                        <div class="mb-3">
                          <app-checkbox [labelClass]="isClientNoShowDisabled?'opacity-50':'opacity-100'" [isHardCoded]="isClientNoShowDisabled" [readonly]="isClientNoShowDisabled" [id]="1" class="d-flex align-self-center" [status]="checkReasonForCancellation(check_box_type.CLIENTNOSHOW)" (check)="selectCheckBox($event ,check_box_type.CLIENTNOSHOW); this.completeAppoinmentModel.appointmentCompletionStateTypeId=check_box_type.CLIENTNOSHOW" [lableName]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CLIENTNOSHOW' | translate"></app-checkbox>
                        </div>
                        <div class="mb-3">
                          <app-checkbox [labelClass]="isEndedEarlyDisabled?'opacity-50':'opacity-100'" [isHardCoded]="isEndedEarlyDisabled" [readonly]="isEndedEarlyDisabled"  [id]="2" class="d-flex align-self-center" [status]="checkReasonForCancellation(check_box_type.ENDEDEARLY)" (check)="selectCheckBox($event,check_box_type.ENDEDEARLY); this.completeAppoinmentModel.appointmentCompletionStateTypeId=check_box_type.ENDEDEARLY" [lableName]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDEDEARLY' | translate"></app-checkbox>
                        </div>
                        <div class="">
                          <app-checkbox [labelClass]="editAppointmentListData?.checkingRequestIsDuringOrAfter?.isDuringAppointment || isEndedLateDisabled?'opacity-50':'opacity-100'" [isHardCoded]="editAppointmentListData?.checkingRequestIsDuringOrAfter?.isDuringAppointment || isEndedLateDisabled" [readonly]="editAppointmentListData?.checkingRequestIsDuringOrAfter?.isDuringAppointment || isEndedLateDisabled" [id]="3" class="d-flex align-self-center" [status]="checkReasonForCancellation(check_box_type.ENDEDLATE)" (check)="selectCheckBox($event,check_box_type.ENDEDLATE); this.completeAppoinmentModel.appointmentCompletionStateTypeId=check_box_type.ENDEDLATE" [lableName]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDEDLATE' | translate"></app-checkbox>
                        </div>
                      </div>
                      <div *ngIf="checkReasonForCancellation(check_box_type.ENDEDEARLY)" class="col-md-6 col-lg-6 col-12 pe-point-75rem Request-Category mb-2">
                        <app-dropdown [dynamic_class]="'filterDropdown'" [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.UPDATEAPPOINTMENTFORM.PLACEHOLDER.SELECTREASON' | translate"
                        [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.UPDATEAPPOINTMENTFORM.LABEL.REASON' | translate" 
                        [dropdownValue]="reasonsForEndedEarly"
                        [isRequired]="true"
                        (dropdownValueEmit)="completeAppoinmentModel.reasonsForEndedEarly=$event;"
                        [dropDownList]="loadDropdownDataService.commonGetDropdownModel.reasonsForEndedEarly" [isRequired]="true"
                        [id]="'reason'">
                      </app-dropdown>
                      <!-- (dropdownValueEmit)="$event;visibleForm = showFormView.FormStart;clearProgress()" -->
                    </div>
                      
                      <div class="form-group d-flex flex-wrap ">
                        <div class="col-md-12 col-lg-12 col-12 flex-column d-flex ">
                          <label for="Interpreter Notes" class="form-label">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERPRETERNOTES' | translate}}</label>
                          <textarea [ngClass]="{'bg-disabled':!(userDetails?.organizations[0]?.role?.value == roles.Interpreter)}" [disabled]="!(userDetails?.organizations[0]?.role?.value == roles.Interpreter)" id="Interpreter_Notes"  class="textarea-notes " name="Interpreter_Notes" rows="4" [(ngModel)]="completeAppoinmentModel.interpreterNote" (input)="checkCharacterCount()"
                            >{{editNotesData?.lenght}}/750
                          </textarea>
                          <div class="d-flex justify-content-end align-items-center gap-1 mt-2" *ngIf="isError" [ngClass]="{ 'error-message': isError }">
                            
                            <img  src="../../../../../../assets/images/svg-icon/info.svg" alt="" height="15">
                            <p class="text-end error-message m-0" >Max 750 characters</p>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="form-group row py-2">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
                      <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="activeModal.close()"
                        [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'button'" [id]="'update_cancel_button'"
                        [name]="'update_cancel_button'"></app-button>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
                      <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.SAVE'"
                        [class.cursor-notAllowed]="CreateRequestForm4?.invalid"
                        [buttonClass]="'button-primary buttonBig w-65px'"
                        [buttonType]="'submit'" 
                        [id]="'update_submit_button'"
                        [disabled]="CreateRequestForm4?.invalid"
                        [name]="'update_submit_button'" (buttonOutputEvent)="updateCompleteAppointmentByInterpreter()"></app-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
      </div>
    </div>
  </div>  
