<div class="container-fluid px-2">
  <div class="page-title mb-0">
    <div class="row mt-md-2 mt-lg-2">
      <div class="col-md-6 col-4 ps-0 ps-md-0">
        <span class="main-title text-nowrap text-truncate">{{ title | translate }} {{appointmentNo? ' - '+ appointmentNo:''}}</span>
      </div>
      <div class="col-md-6 col-8 ps-5 pe-0 pe-md-0">
        <ol class="breadcrumb mx-2">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard'">
              <svg xmlns="http://www.w3.org/2000/svg" width="19.998" height="20" viewBox="0 0 19.998 20">
                <path id="home" d="M19.961,8.7l0,0L11.8.539a1.841,1.841,0,0,0-2.6,0L1.043,8.693,1.035,8.7A1.84,1.84,0,0,0,2.26,11.838l.057,0h.325v6A2.157,2.157,0,0,0,4.8,20H7.988a.586.586,0,0,0,.586-.586V14.706a.984.984,0,0,1,.983-.983H11.44a.984.984,0,0,1,.983.983v4.707a.586.586,0,0,0,.586.586H16.2a2.157,2.157,0,0,0,2.155-2.155v-6h.3a1.842,1.842,0,0,0,1.3-3.142Zm-.83,1.774a.665.665,0,0,1-.473.2H17.77a.586.586,0,0,0-.586.586v6.59a.984.984,0,0,1-.983.983H13.6V14.706a2.157,2.157,0,0,0-2.155-2.155H9.558A2.158,2.158,0,0,0,7.4,14.706v4.121H4.8a.984.984,0,0,1-.983-.983v-6.59a.586.586,0,0,0-.586-.586h-.9a.669.669,0,0,1-.461-1.142h0l8.158-8.158a.669.669,0,0,1,.946,0l8.156,8.156,0,0a.671.671,0,0,1,0,.946Zm0,0" transform="translate(-0.5 0.001)" fill="#727272"/>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item text-truncate" *ngFor="let item of items">{{ item | translate}}</li>
          <li class="breadcrumb-item text-truncate active">{{ active_item | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>