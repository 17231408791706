<div class="container-fluid p-4">
    <div class="row">
        <div class="col-12">
            <span class="d-flex align-items-center popUpheader">
                <span class="modalTitle">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.ASSIGNINTERPRETER' | translate}}</span>
            </span>
            <div class="d-flex align-items-center">
                <span class="me-4 requestTypeTitle text-nowrap">{{getRequestData?.appointmentCategoryId==requestCategory.LinguisticMatchCall?('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.LINGUISTICMATCHCALL' | translate):('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.INTERPRETATIONREQUEST' | translate)}}</span>
                <span class="requestDate">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_CREATED_ON' | translate}} {{getRequestData?.createdDate | date:'mediumDate'}}</span>
            </div>
        </div>
        <div class="col-12 md-height-400 ">
            <div class="d-flex flex-wrap my-3" *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.LinguisticMatchCall">
                <div class="font-semi-bold color-primary f-12 me-3">{{getRequestData?.matchCallLanguage[0]?.language??"NA"}}</div>
                <div class="font-Regular color-primary f-12 me-3 text-truncate col-xl-3" [title]="getRequestData?.organization?.value">{{getRequestData?.organization?.value??"NA"}}</div>
                <div class="font-Regular color-primary f-12 me-3">{{getRequestData?.date | date:"MM/dd/yyyy"}}</div>
                <div class="font-Regular color-primary f-12">{{getRequestData?.startTime | timeFormatAMPM }} {{getRequestData?.appointmentCategoryId!=requestCategory?.LinguisticMatchCall?"-":''}} {{getRequestData?.appointmentCategoryId!=requestCategory?.LinguisticMatchCall?getRequestData?.endTime:""}}</div>
                <div class="d-flex flex-wrap justify-content-between mb-3 col-12">
                    <div class="col-3">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LOCATION' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.location}}</span>
                    </div>
                    <div class="col-4">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.interpreterGenderRequest?.value}}</span>
                    </div>
                    <div class="col-5">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK/NUMBER' | translate}}</span>
                        <br>
                        <span *ngIf=" (getRequestData?.link && getRequestData?.phoneNumber)" class="font-Regular color-primary-grey f-12">{{ getRequestData?.phoneNumber}} <span class="px-2 color-primary-grey"> / </span> {{getRequestData?.link}}</span>
                        <span *ngIf="!(getRequestData?.link && getRequestData?.phoneNumber)" class="font-Regular color-primary-grey f-12">{{ getRequestData?.link  ? getRequestData?.link  :  getRequestData?.phoneNumber ? getRequestData?.phoneNumber : 'COMMON.NOTAVAILABLE' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between w-100">
                    <div class="col-12">
                        <span class="font-semi-bold color-primary-grey f-12">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_VARIANTS' | translate}}</span>
                        <br>
                        <div class="d-flex flex-column overflow-auto variant-section mb-2">
                            <div class="d-flex" *ngFor="let item of getRequestData?.matchCallLanguage">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex align-items-center">
                                        <svg class="me-2 m-t-5" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                                            <circle id="Ellipse_69" data-name="Ellipse 69" cx="2.5" cy="2.5" r="2.5" fill="#047bbc"/>
                                        </svg>
                                        <span class="font-Regular color-primary f-12">{{item?.variant}}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="font-Regular color-primary-grey f-12 mb-1">
                                        {{item?.description}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="col-12 mb-2">
                        <div class="interpreter-section mb-1">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_INTERPRETERS' | translate}}<span class="requestDate ms-1">(Max 5)</span></div>
                        <ul class="d-flex column-gap-0_5rem row-gap-0_25rem flex-wrap scrollUl">
                            <li *ngIf="langWithInterpreterList==undefined || langWithInterpreterList.length==0" class="f-10 color-primary-grey font-Regular">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_NO_INTERPRETERS_SELECTED' | translate}}</li>
                            <ng-container  *ngFor="let item of langWithInterpreterList;let i=index">
                                <li class="small-buttons" >
                                    <app-button  [isCrossIcon]="true" (crossButtonOutputEvent)="remove(i)" class="justify-content-end"  [buttonLabel]="item.name" [buttonClass]="'button-primary font-Regular ps-2 pe-2 f-12'"></app-button>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-12">
                        <div class="interpreter-section me-1 mb-1 pb-1">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_INTERPRETER.SELECT' | translate}}</div>
                        <form *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.LinguisticMatchCall" class="row gx-2" ngNativeValidate #AssignForm="ngForm">
                            <div class="col-4 pe-1 mb-3">
                                <!-- <app-dropdown [isDropdownDisabled]="langWithInterpreterList==undefined || langWithInterpreterList.length!=5?false:true" [showPlaceholderText]="'Select Language'" 
                                [dropdownValue]="selectedInterpreterModel.languageId" (dropdownValueEmit)="selectedInterpreterModel.languageId=$event"
                                [dropDownList]="languageList"
                                [id]="'language_of_interpreter'"></app-dropdown> -->
                                <app-custom-dropdown [id]="'language_of_interpreter'" [isRequired]="true" [isDisabled]="langWithInterpreterList.length >= 5" (buttonOutputEvent)="addNewLanguages($event)" [selectedlanguagesData]="copyuserSelectedLanguages" [singleSelectedlanguage]="seletedlanguage" [languageDropDownData]="languagesListData" [addLanguageIcon]="false"></app-custom-dropdown>
                            </div>
                            <div class="col-8 ps-1 mb-4">
                                <app-dropdown [isDropdownDisabled]="langWithInterpreterList.length >= 5" [showPlaceholderText]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_INTERPRETER.SELECT'"
                                [dropdownValue]="selectedInterpreterModel.interpreterId" (dropdownValueEmit)="selectedInterpreterModel.interpreterId=$event"
                                [dropDownList]="interpreterList"
                                [isRequired]="false"
                                [id]="'interpreter'"></app-dropdown>
                            </div>
                            <p *ngIf="langWithInterpreterList?.length!=5" class="text-center"><span class="addInterpreter f-12" [ngClass]="{'pe-none opacity-50': !newGetInterpreterModel?.languageVariantId}" (click)="addInterpreter()" >+ {{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_ADD_INTERPRETER' | translate}}</span>
                            </p>
                            <app-checkbox class="mb-3" [name]="'checkbox_linguisticRequest'" [colorDarkGray]="true" [lableName]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_SHOW_UNAVAILABLE_INTERPRETERS'" [status]="getInterpreterModel?.isUnavailableInterpretersIncluded" (check)="getInterpreterModel.isUnavailableInterpretersIncluded = $event;getInterpreterlist();"></app-checkbox>
                        </form>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap my-3" *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.InterpretationRequest">
                
                <div class="font-semi-bold color-primary f-12 mb-2">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_SELECT_LANGUAGE_PAIRING_TO_ASSIGN'| translate}}</div>
                <div class="d-flex flex-wrap mb-3 col-12">
                    <div class="col-12" *ngIf="getRequestData?.interpretationLanguages?.length==2">
                        <form class="d-flex col-12 flex-sm-row flex-column">
                            <div class="col">
                                <div class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.TABLE.PREFEREDLANGPAIR' | translate}}</div>
                                <app-radiobutton [id]="'preferredLanguage'" [name]="'radio3'" [radioButtonLabel]="getRequestData?.interpretationLanguages[1]?.fromLang?.value + (getRequestData?.interpretationLanguages[1]?.fromLang?.value ? ' to ' + getRequestData?.interpretationLanguages[1]?.toLang?.value : ' NA')" [radioButtonValue]="false" [status]="assignModal.isAcceptableLanguage" (check)="assignModal.isAcceptableLanguage=$event;getInterpreterlist()" [isRequired]="true"></app-radiobutton>                          
                            </div>
                            <div class="col">
                                <div class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.TABLE.ACCEPTABLELANGPAIR' | translate}}</div>
                                <app-radiobutton [id]="'acceptableLanguage'" [name]="'radio4'" [radioButtonLabel]="getRequestData?.interpretationLanguages[0]?.fromLang?.value + (getRequestData?.interpretationLanguages[0]?.fromLang?.value ? ' to ' + getRequestData?.interpretationLanguages[0]?.toLang?.value : ' NA')" [radioButtonValue]="true" [status]="assignModal.isAcceptableLanguage" (check)="assignModal.isAcceptableLanguage=$event;getInterpreterlist()" [isRequired]="true"></app-radiobutton>                           
                            </div>
                        </form>
                    </div>
                    <div class="col-12" *ngIf="getRequestData?.interpretationLanguages?.length==1">
                        <form class="d-flex col-12">
                            <div class="col-6">
                                <div class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.TABLE.PREFEREDLANGPAIR' | translate}}</div>
                                <app-radiobutton [id]="'preferredLanguage'" [name]="'radio3'" [radioButtonLabel]="getRequestData?.interpretationLanguages[0]?.fromLang?.value + (getRequestData?.interpretationLanguages[0]?.fromLang?.value ? ' to ' + getRequestData?.interpretationLanguages[0]?.toLang?.value : ' NA')" [radioButtonValue]="false" [status]="assignModal.isAcceptableLanguage" (check)="assignModal.isAcceptableLanguage=$event;getInterpreterlist()" [isRequired]="true"></app-radiobutton>                          
                            </div>
                        </form>
                    </div>
                </div>

                <div class="d-flex flex-sm-row flex-column gap-2">
                    <div class="font-Regular color-primary f-12 me-3">{{getRequestData?.organization?.value}}</div>
                    <div class="d-flex">
                        <div class="font-Regular color-primary f-12 me-3">{{getRequestData?.date | date:"MM/dd/yyyy"}}</div>
                        <div class="font-Regular color-primary f-12 mb-3">{{getRequestData?.startTime | timeFormatAMPM }} - {{getRequestData?.endTime | timeFormatAMPM}}</div>
                    </div>
                </div>
                    <div class="d-flex flex-wrap justify-content-between mb-3 col-12 gap-4 row-gap-1">
                    <div class="col-3">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SERVICETYPE' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.service?.value}}</span>
                    </div>
                    <div class="col">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.COMMUNICATIONTYPE' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.communicationType?.value}}</span>
                    </div>
                    <div class="col">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SCHEDULEOPTION' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.scheduleOptionsId == scheduleOptions.OnDemand?'OnDemand':'Pre-Scheduled'}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-3 col-12">
                    <div class="col-4">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LOCATION' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.location}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-4 gap-4 col-12">
                    <div class="col-3">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST' | translate}}</span>
                        <br>
                        <span class="font-Regular color-primary-grey f-12">{{getRequestData?.interpreterGenderRequest?.value}}</span>
                    </div>
                    <div class="col">
                        <span class="font-semi-bold color-primary-grey f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK/NUMBER' | translate}}</span>
                        <br>
                        <span *ngIf="(getRequestData?.link  && getRequestData?.phoneNumber )" class="font-Regular color-primary-grey f-12">{{ getRequestData?.phoneNumber}} <span class="px-2 color-primary-grey"> / </span> {{getRequestData?.link}}</span>
                        <span *ngIf="!(getRequestData?.link  && getRequestData?.phoneNumber )" class="font-Regular color-primary-grey f-12">{{ getRequestData?.link  ? getRequestData?.link  :  getRequestData?.phoneNumber ? getRequestData?.phoneNumber : 'COMMON.NOTAVAILABLE' | translate}}</span>
                    </div>
                </div>
                <form *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.InterpretationRequest" class="w-100 theme-form" ngNativeValidate #AssignForm="ngForm">
                    <div class="d-flex flex-wrap mb-4 col-12">
                        <div class="col-12">
                            <div class="font-semi-bold color-primary-grey f-12">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_ASSIGNMENT_METHOD' | translate}}</div>
                            <div class="d-flex col-12 flex-wrap flex-sm-row flex-column">
                                <div class="col">
                                    <app-radiobutton [id]="'autoMultiAssignment'" [name]="'radio1'" [radioButtonLabel]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_AUTOMATIC_MULTI-ASSIGNMENT'" [radioButtonValue]="assignationProcessType.Automatic" [status]="assignModal.assignationProcessTypeId" (check)="assignModal.assignationProcessTypeId=$event" [isRequired]="true"></app-radiobutton>                          
                                </div>
                                <div class="col">
                                    <app-radiobutton [id]="'manualAsssignment'" [name]="'radio2'" [radioButtonLabel]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_MANUAL_ASSIGNMENT'" [radioButtonValue]="assignationProcessType.Manual" [status]="assignModal.assignationProcessTypeId" (check)="assignModal.assignationProcessTypeId=$event" [isRequired]="true"></app-radiobutton>                           
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="interpreter-section me-1 mb-1">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_INTERPRETER.SELECT' | translate}}</div>
    
                        <div class="row gx-2 mb-2">
                            <div>
                                <div class="col-8 ps-0 mb-2 pb-1">
                                    <app-dropdown [isDropdownDisabled]="assignModal?.assignationProcessTypeId==assignationProcessType?.Automatic" [showPlaceholderText]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_INTERPRETER.SELECT'" 
                                        [dropdownValue]="assignModal?.interpretersUserId[0]" (dropdownValueEmit)="assignModal?.interpretersUserId[0]=$event"
                                        [dropDownList]="interpreterList" [isRequired]="assignModal?.assignationProcessTypeId==assignationProcessType?.Manual"
                                    [id]="'interpreter_interpretationRequest'" [name]="'interpreter_interpretationRequest'"></app-dropdown>
                                </div>
                                <app-checkbox [id]="'checkbox_interpretationRequest'" [name]="'checkbox_interpretationRequest'" [colorDarkGray]="assignModal.assignationProcessTypeId==assignationProcessType.Manual" [colorGray]="assignModal?.assignationProcessTypeId==assignationProcessType?.Automatic" class="mb-3" [lableName]="'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_SHOW_UNAVAILABLE_INTERPRETERS'" [status]="getInterpreterModel.isUnavailableInterpretersIncluded" (check)="getInterpreterModel.isUnavailableInterpretersIncluded = $event;newGetInterpreterModel.isUnavailableInterpretersIncluded= $event;getInterpreterlist();" [readonly]="assignModal?.assignationProcessTypeId==assignationProcessType?.Automatic"></app-checkbox>
                            </div>
                        </div>
                    </div>
                </form>
        </div>
    </div>
    <div>
        <div class="form-group row pt-5">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-end justify-content-end">
            <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'" (click)="activeModal.close()"
                [buttonClass]="'button-secondary buttonBig w-65px'" [buttonType]="'button'" [id]="'assignInterpreter_cancel_button'"
                [name]="'assignInterpreter_cancel_button'"></app-button>
            </div>
            <!-- For InterpretationRequest -->
            <div *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.InterpretationRequest" class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
            <app-button 
                [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.SEND'"
                [disabled]="AssignForm?.invalid"
                [buttonClass]="true?'button-primary buttonBig w-65px':'button-disabled buttonBig w-65px'"
                [buttonType]="'submit'" 
                [id]="'assignInterpreter_send_button'" 
                [name]="'assignInterpreter_send_button'"
                (buttonOutputEvent)="InternalAssignappointment(getRequestData)">
            </app-button>
            </div>
            <!-- For LinguisticMatchCall -->
            <div *ngIf="getRequestData?.appointmentCategoryId==requestCategory?.LinguisticMatchCall" class="col-md-6 col-sm-6 col-lg-6 col-xl-6 col-6 d-flex justify-content-md-start justify-content-start">
                <app-button 
                    [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.SEND'"
                    [disabled]="langWithInterpreterList.length < 1"
                    [buttonClass]="true?'button-primary buttonBig w-65px':'button-disabled buttonBig w-65px'"
                    [buttonType]="'submit'" 
                    [id]="'assignInterpreter_send_button'" 
                    [name]="'assignInterpreter_send_button'"
                    (buttonOutputEvent)="InternalAssignappointment(getRequestData)">
                </app-button>
            </div>
        </div>
    </div>
</div>